import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-arabic-map',
  templateUrl: './dialog-arabic-map.component.html',
  styles: [
  ]
})
export class DialogArabicMapComponent {

}

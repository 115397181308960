import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { GeneralService } from 'src/app/shared/general.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-points',
  templateUrl: './points.component.html',
  styles: [
  ]
})
export class PointsComponent implements OnInit {
  Company_List = [];
  Point_Consumbtions_List = [];
  approved_products = [];
  online_products = [];
  onfront_page_products = [];
  Pictures_Url = environment.Pictures_Url;

  single_product_online_price : number= 0;
  single_product_onfrontpage_price : number = 0;

  selected_online_ts_id : number = 0;
  selected_online_ts_id_date : number = 0;
  selected_frontpage_ts_id : number = 0;
  selected_frontpage_ts_id_date : number = 0;

  FG_Branches = this.FB.group({
    Company_Id: [{ value: 0}],
    Company_Name: ['', [Validators.required, Validators.maxLength(50)]],
    Company_Status: ['', Validators.required ],
    Company_Points: [0, Validators.required ],
    Company_No_of_Products_on_front_page: [0],
    Company_No_of_Online_Products: [0],
    Company_No_of_approved_Products: [0],
    Company_No_of_pending_Products: [0],
    Company_No_of_denied_Products: [0],
    Company_logo_path:[''],
    });
  constructor(private notificationservice : NotificationService,private service: GeneralService, public FB: UntypedFormBuilder, private datePipe: DatePipe ) { }

  ngOnInit(): void {
    this.refresh_companies_list()
  }

  refresh_companies_list() {
    this.service.load_companies().subscribe(res => {
      this.Company_List = res as [];
      if  (this.Company_List.length == 1){
        this.copmany_id_change(this.Company_List[0].company_id);
      }
    });
  }
  copmany_id_change(company_id_temp) {
    this.service.load_one_company(company_id_temp).subscribe(res => {
      this.single_product_online_price = res.single_product_online_price;
      this.single_product_onfrontpage_price = res.single_product_onfrontpage_price;
      this.Point_Consumbtions_List = Object.values(res['point_Consumbtions']);
      this.approved_products = Object.values(res['approved_products']);
      this.online_products = Object.values(res['online_products']);
      this.onfront_page_products = Object.values(res['onfront_page_products']);
      this.FG_Branches.patchValue({
        Company_Id: res.companies_Summary.company_Id,
        Company_Name: res.companies_Summary.company_Name,
        Company_Status: res.companies_Summary.company_Statuses_name,
        Company_Points: res.companies_Summary.company_Points,
        Company_No_of_Products_on_front_page: res.companies_Summary.company_No_of_Products_on_front_page,
        Company_No_of_Online_Products: res.companies_Summary.company_No_of_Online_Products,
        Company_No_of_approved_Products: res.companies_Summary.company_No_of_approved_Products,
        Company_No_of_pending_Products: res.companies_Summary.company_No_of_pending_Products,
        Company_No_of_denied_Products: res.companies_Summary.company_No_of_denied_Products,
        Company_logo_path: res.companies_Summary.company_logo_path,        
      });
    });
  }
add_online_ts(){
    this.service.dialog_add_online_timeslot(this.FG_Branches.controls['Company_Id'].value,this.FG_Branches.controls['Company_Points'].value, this.single_product_online_price)
    .afterClosed().subscribe(res=>{
        if (res) { 
          this.copmany_id_change(this.FG_Branches.controls['Company_Id'].value)
          this.notificationservice.send_success_message("Online Time Slot is added"); this.refresh_companies_list();}
        else { this.notificationservice.send_fail_message("Online Time Slot is not added"); this.refresh_companies_list();}
      }
      , err => { this.notificationservice.send_fail_message("Could not add Online Time Slot"); this.refresh_companies_list();})
  }
add_frontpage_ts(){
    this.service.dialog_company_add_front_TS(this.FG_Branches.controls['Company_Id'].value,this.FG_Branches.controls['Company_Points'].value, this.single_product_onfrontpage_price)
    .afterClosed().subscribe(res=>{
        if (res) { 
          this.copmany_id_change(this.FG_Branches.controls['Company_Id'].value)
          this.notificationservice.send_success_message("Online Time Slot is added"); this.refresh_companies_list();}
        else { this.notificationservice.send_fail_message("Online Time Slot is not added"); this.refresh_companies_list();}
      }
      , err => { this.notificationservice.send_fail_message("Could not add Online Time Slot"); this.refresh_companies_list();})
  }
filterItemsOfType(type){
  return this.Point_Consumbtions_List.filter(x => x.point_Consumbtion_IF_Front_Page == type);
}
filter_online_products(){
  return this.online_products.filter(x => x.product_Related_Point_Id == this.selected_online_ts_id && x.product_Related_Point_Id_Date == this.selected_online_ts_id_date);
}
filter_frontpage_products(){
  return this.onfront_page_products.filter(x => x.product_Related_Point_Id == this.selected_frontpage_ts_id && x.product_Related_Point_Id_Date == this.selected_frontpage_ts_id_date);
}
extend_online_ts(Time_Slot_Id, Time_Slot_Id_Date,no_time_slots){
  this.service.dialog_extend_online_timeslot(this.FG_Branches.controls['Company_Id'].value,this.FG_Branches.controls['Company_Points'].value, this.single_product_online_price, Time_Slot_Id, Time_Slot_Id_Date, no_time_slots)
  .afterClosed().subscribe(res=>{
      if (res) { 
        this.copmany_id_change(this.FG_Branches.controls['Company_Id'].value)
        this.notificationservice.send_success_message("Online Time Slot is extended"); this.refresh_companies_list();}
      else { this.notificationservice.send_fail_message("Online Time Slot is not extended"); this.refresh_companies_list();}
    }
    , err => { this.notificationservice.send_fail_message("Could not extend Online Time Slot"); this.refresh_companies_list();})
}
extend_frontpage_ts(Time_Slot_Id, Time_Slot_Id_Date, no_time_slots){
  this.service.dialog_company_extend_front_TS(this.FG_Branches.controls['Company_Id'].value,this.FG_Branches.controls['Company_Points'].value, this.single_product_onfrontpage_price, Time_Slot_Id, Time_Slot_Id_Date, no_time_slots)
  .afterClosed().subscribe(res=>{
      if (res) { 
        this.copmany_id_change(this.FG_Branches.controls['Company_Id'].value)
        this.notificationservice.send_success_message("Online Time Slot is added");
         this.refresh_companies_list();}
      else { this.notificationservice.send_fail_message("Online Time Slot is not added"); this.refresh_companies_list();}
    }
    , err => { this.notificationservice.send_fail_message("Could not add Online Time Slot"); this.refresh_companies_list();})
}
select_online_ts(point_Consumbtion_Id,point_Consumbtion_Id_Date){
  this.selected_online_ts_id =point_Consumbtion_Id;
  this.selected_online_ts_id_date =point_Consumbtion_Id_Date;
}
select_frontpage_ts(point_Consumbtion_Id,point_Consumbtion_Id_Date){
  this.selected_frontpage_ts_id =point_Consumbtion_Id;
  this.selected_frontpage_ts_id_date =point_Consumbtion_Id_Date;
}
focusout(){
  this.selected_online_ts_id =0;
  this.selected_online_ts_id_date =0;
  this.selected_frontpage_ts_id  = 0;
  this.selected_frontpage_ts_id_date  = 0;
}
put_product_online(Archived_Product_Id , Archived_Product_Id_Date){
  if (this.selected_online_ts_id != 0 && this.selected_online_ts_id_date != 0) {
      var body = {
        "Archived_Product_Id": Archived_Product_Id,
        "Archived_Product_Id_Date": Archived_Product_Id_Date,
        "Archived_Product_Last_Point_Consumption_Id": this.selected_online_ts_id,
        "Archived_Product_Last_Point_Consumption_Id_Date": this.selected_online_ts_id_date,
      }
      this.service.put_product_online(body).subscribe(res => { this.copmany_id_change(this.FG_Branches.controls['Company_Id'].value); this.notificationservice.send_success_message("Product set Online successfully");}, err => { this.notificationservice.send_fail_message("Failed to set Product to Online"); })
    }
  else { this.notificationservice.send_fail_message("Please select Online Time Slot first"); }
}
put_product_offline(Archived_Product_Id , Archived_Product_Id_Date){
  if (Archived_Product_Id != 0 && Archived_Product_Id_Date != 0) {
    var body = {
      "Archived_Product_Id": Archived_Product_Id,
      "Archived_Product_Id_Date": Archived_Product_Id_Date,
    }
    this.service.put_product_offline(body).subscribe(res => {this.copmany_id_change(this.FG_Branches.controls['Company_Id'].value); this.notificationservice.send_success_message("Product set Offline successfully");}, err => { this.notificationservice.send_fail_message("Failed to set Product to Offline"); })
  }
else { this.notificationservice.send_fail_message("Error in selecting Product"); }
}
put_product_frontpage(Archived_Product_Id , Archived_Product_Id_Date){
  if (this.selected_frontpage_ts_id != 0 && this.selected_frontpage_ts_id_date != 0) {
      var body = {
        "Archived_Product_Id": Archived_Product_Id,
        "Archived_Product_Id_Date": Archived_Product_Id_Date,
        "Archived_Product_Last_Point_Consumption_Id": this.selected_frontpage_ts_id,
        "Archived_Product_Last_Point_Consumption_Id_Date": this.selected_frontpage_ts_id_date,
      }
      this.service.put_product_onfrontpage(body).subscribe(res => { this.copmany_id_change(this.FG_Branches.controls['Company_Id'].value); this.notificationservice.send_success_message("Your Product set On TheNileZone Front Page successfully");}, err => { this.notificationservice.send_fail_message("Failed to set Product to On TheNileZone Front Page"); })
    }
  else { this.notificationservice.send_fail_message("Please select On Front Page Time Slot first"); }
}
remove_product_frontpage(Archived_Product_Id , Archived_Product_Id_Date){
  if (this.selected_frontpage_ts_id != 0 && this.selected_frontpage_ts_id_date != 0) {
    var body = {
      "Archived_Product_Id": Archived_Product_Id,
      "Archived_Product_Id_Date": Archived_Product_Id_Date,
      "Archived_Product_Last_Point_Consumption_Id": this.selected_frontpage_ts_id,
      "Archived_Product_Last_Point_Consumption_Id_Date": this.selected_frontpage_ts_id_date,
    }
    this.service.remove_product_onfrontpage(body).subscribe(res => {this.copmany_id_change(this.FG_Branches.controls['Company_Id'].value); this.notificationservice.send_success_message("Your Product removed from TheNileZone Front Page successfully");}, err => { this.notificationservice.send_fail_message("Failed to remove Your Product from TheNileZone Front Page "); })
  }
else { this.notificationservice.send_fail_message("Error in selecting Product"); }
}
check_if_product_onfrontpage(Archived_Product_Id , Archived_Product_Id_Date){
  if((this.onfront_page_products.filter(x => x.product_Id == Archived_Product_Id && x.product_Id_Date == Archived_Product_Id_Date && x.product_Related_Point_Id == this.selected_frontpage_ts_id && x.product_Related_Point_Id_Date == this.selected_frontpage_ts_id_date)).length)
  {return true}
  else{return false}
}
}
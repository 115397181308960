import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GeneralService } from 'src/app/shared/general.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { InsertCompanyComponent } from '../insert-company/insert-company.component';

@Component({
  selector: 'app-insert-company-arabic',
  templateUrl: './insert-company-arabic.component.html',
  styles: [
  ]
})
export class InsertCompanyArabicComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data,public dialogRef: MatDialogRef<InsertCompanyArabicComponent>, public FB: UntypedFormBuilder, public service: GeneralService, private notificationservice: NotificationService) { }
  formGroup_Create_Company = this.FB.group({
    Company_Name: [{value:'', disabled: false }, Validators.compose([Validators.required, Validators.maxLength(50)])],  //(control as FormControl)
    Company_Desc: ['', [Validators.required, Validators.maxLength(300)]],
    Company_Note: ['', [Validators.maxLength(300)]],
    Company_Desc_Arabic: [{value:'', disabled: false }, [Validators.required,Validators.maxLength(300)]],
    Company_Name_Arabic: [{value:'', disabled: false }, [Validators.required, Validators.maxLength(50)]],
    Company_Phone_1: [{value:'', disabled: false }, [Validators.required, Validators.maxLength(25)]],
    Company_Phone_2: [{value:'', disabled: false }, [Validators.maxLength(25)]],
    Company_Phone_3: [{value:'', disabled: false }, [Validators.maxLength(25)]],
    Company_Phone_4: [{value:'', disabled: false }, [Validators.maxLength(25)]],
    Company_Has_Location: [{value:true}],
  })
  ngOnInit(): void {
  }
  cancel(){
    this.dialogRef.close(false);
  }
  create_company(formGroup_Create_Company){
    if (formGroup_Create_Company.valid) {
        var body = {
          "Company_Name": this.formGroup_Create_Company.controls['Company_Name'].value,
          "Company_Name_Arabic":  this.formGroup_Create_Company.controls['Company_Name_Arabic'].value,
          "Company_Desc":  this.formGroup_Create_Company.controls['Company_Desc'].value,
          "Company_Desc_Arabic":  this.formGroup_Create_Company.controls['Company_Desc_Arabic'].value,
          "Company_Note":  this.formGroup_Create_Company.controls['Company_Note'].value,
          "Company_Phone_1":  this.formGroup_Create_Company.controls['Company_Phone_1'].value.toString(),
          "Company_Phone_2":  this.formGroup_Create_Company.controls['Company_Phone_2'].value.toString(),
          "Company_Phone_3":  this.formGroup_Create_Company.controls['Company_Phone_3'].value.toString(),
          "Company_Phone_4":  this.formGroup_Create_Company.controls['Company_Phone_4'].value.toString(),
          "Company_Has_Location":  this.formGroup_Create_Company.controls['Company_Has_Location'].value.value,
        }
        this.service.create_company(body).subscribe(res => { this.notificationservice.send_success_message("تم انشاء شركتك"); this.dialogRef.close(true); }, 
        err => { this.notificationservice.send_fail_message("لم يتم أنشاء شركتك. ربما اسم الشركة تم استخدامه من قبل نرجو تغيير الاسم."); })
    }
    else { this.notificationservice.send_fail_message("مشكلة في بيانات الشركة"); }
  }

}

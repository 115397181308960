// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BaseUrl : 'https://shops.thenilezone.com/api/',  // should work at The nilezone
  // BaseUrl : 'http://localhost:13603/api/', // working from angular and from core
  //BaseUrl : 'http://mramzy-001-site1.btempurl.com/api/', // should work at smarterasp.net
  // BaseUrl : 'http://www.thenilezone.com/api/',  // should work at The nilezone  
  // Image_Folder_Path : 'E:/Programming projects/34- The NileZone/Core/TheNileZoneAdmin/TheNileZoneAdmin/TheNileZoneAdmin/Images/',
  // Pictures_Url : '../../../assets/images/',
  Pictures_Url : 'http://shops.thenilezone.com/assets/images/', 
  recaptcha: {siteKey: '6Le5tK4nAAAAAKp6XVMZJCVXvgJy_4EYobhlBd_z',  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

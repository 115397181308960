import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { GeneralService } from 'src/app/shared/general.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { environment } from 'src/environments/environment';
import { AddCompanyPhotoComponent } from '../../add-company-photo/add-company-photo.component';

@Component({
  selector: 'app-view-company-photos-arabic',
  templateUrl: './view-company-photos-arabic.component.html',
  styles: [
  ]
})
export class ViewCompanyPhotosArabicComponent {photo_list = [];
  copmany_id_select:number=0;
  picture_id_select:number=0;
  initial_Pic_Name = environment.Pictures_Url +  'Companies_Id/brand10_resized.webp';
  constructor(public service: GeneralService, private notificationservice: NotificationService, @Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<AddCompanyPhotoComponent>, private sanitizer: DomSanitizer, public FB: UntypedFormBuilder) { }
  ngOnInit(): void {
    this.copmany_id_select = this.data.company_id
    this.load_pictures(this.copmany_id_select)
  }
  closeDialog() { this.dialogRef.close(); }
  
  load_pictures(company_id_temp) {
    this.service.companies_identites(company_id_temp).subscribe(res => {
      this.photo_list = res as any;
    })
  }
  select_online_ts(company_Picture_Id,company_Picture_Store_Name,company_Picture_Extention){
    this.picture_id_select = company_Picture_Id
    this.initial_Pic_Name = environment.Pictures_Url +  'Companies_Id/' + this.copmany_id_select + '/' + company_Picture_Store_Name + company_Picture_Extention ;
  }
}

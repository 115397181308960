import { Component, Inject, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { GeneralService } from 'src/app/shared/general.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { InsertCompanyComponent } from '../insert-company/insert-company.component';

@Component({
  selector: 'app-view-company-arabic',
  templateUrl: './view-company-arabic.component.html',
  styles: [
  ]
})
export class ViewCompanyArabicComponent implements OnInit {
  company_id = this.data.company_id;formGroup_Create_Company = this.FB.group({
    Company_Name: [{value:'', disabled: true }, Validators.compose([Validators.required, Validators.maxLength(50)])],  //(control as FormControl)
    Company_Desc: [{value:'', disabled: true }, [Validators.required, Validators.maxLength(300)]],
    Company_Note: [{value:'', disabled: true }, [Validators.maxLength(300)]],
    Company_Desc_Arabic: [{value:'', disabled: true }, [Validators.required,Validators.maxLength(300)]],
    Company_Name_Arabic: [{value:'', disabled: true }, [Validators.required, Validators.maxLength(50)]],
    Company_Phone_1: [{value:'', disabled: true }, [Validators.required, Validators.maxLength(25)]],
    Company_Phone_2: [{value:'', disabled: true }, [Validators.maxLength(25)]],
    Company_Phone_3: [{value:'', disabled: true }, [Validators.maxLength(25)]],
    Company_Phone_4: [{value:'', disabled: true }, [Validators.maxLength(25)]],
    Company_Has_Location: [{value:true}],
  })
  reset_FG_User_Related_Branch_display() {
    this.formGroup_Create_Company.patchValue({
      User_Related_Branch_Related_User: '',
      Company_Name: '',
      Company_Desc: '',
      Company_Note: '',
      Company_Desc_Arabic: '',
      Company_Name_Arabic: '',
      Company_Phone_1: '',
      Company_Phone_2: '',
      Company_Phone_3: '',
      Company_Phone_4: '',
      Company_Has_Location: false,
    });
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data, public service: GeneralService,public dialogRef: MatDialogRef<InsertCompanyComponent>,public FB: UntypedFormBuilder, private notificationservice: NotificationService) { }

  ngOnInit(): void {
    this.reset_FG_User_Related_Branch_display();
    this.refresh();
  }
  cancel(){this.dialogRef.close(false);}
  refresh(){   
    this.service.company_Details(this.company_id).subscribe((res: any) => {
       this.formGroup_Create_Company.patchValue({
        $Company_Id: res.company_Id,
        Company_Name: res.company_Name,
        Company_Desc: res.company_Desc,
        Company_Note: res.company_Note,
        Company_Desc_Arabic: res.company_Desc_Arabic,
        Company_Name_Arabic: res.company_Name_Arabic,
        Company_Phone_1: res.company_Phone_1,
        Company_Phone_2: res.company_Phone_2,
        Company_Phone_3: res.company_Phone_3,
        Company_Phone_4: res.company_Phone_4,
        Company_Has_Location : res.company_Has_Location
       });
     }),err=>{this.notificationservice.send_fail_message("Company load Failed");}
  }

}

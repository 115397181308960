import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-master-logged',
  templateUrl: './master-logged.component.html',
  styles: [
  ]
})
export class MasterLoggedComponent implements OnInit {

  constructor(private router: Router) { 
    this.check_language()
  }
  language_param = 0; //1-Arabic 2-English

  ngOnInit(): void {
  }
  check_language(){
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        let link = e.url.toString();
        if(link.includes('/ar/')){
          this.language_param = 1;
        }else{
          this.language_param = 2;
        }
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-map',
  templateUrl: './dialog-map.component.html',
  styles: [
  ]
})
export class DialogMapComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

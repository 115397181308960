import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(public FB: UntypedFormBuilder,private http: HttpClient) { }
  Login_FG = this.FB.group({
    UserName: ['', [Validators.required, Validators.maxLength(49)]],
    Password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(84)]],
    recaptcha: ['', Validators.required],
  });

  Login(formData){
    return this.http.post(environment.BaseUrl+'ApplicationUser/Login',formData);
  }
  reset_Login_FG(){this.Login_FG.setValue({UserName:'',Password:'',recaptcha:''})}
  roleMatch(requiredRoles):boolean{
    var isMatch = false;
    var payLoad = JSON.parse(window.atob(localStorage.getItem('Token').split('.')[1]));
    var userRoles = payLoad.role;
    requiredRoles.forEach(element => {
      if (userRoles.includes(element)) {
      isMatch = true;
      return false;
      }
    });
    return isMatch;
  }
  //cookie management
  public getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }
  public deleteCookie(cookieName) {
    this.setCookie({ name: cookieName, value: '', expireDays: -1 });
  }
  public setCookie(params: any) {
    let d: Date = new Date();
    d.setTime(
      d.getTime() +
        (params.expireDays ? params.expireDays : 1) * 24 * 60 * 60 * 1000
    );
    document.cookie =
      (params.name ? params.name : '') +
      '=' +
      (params.value ? params.value : '') +
      ';' +
      (params.session && params.session == true
        ? ''
        : 'expires=' + d.toUTCString() + ';') +
      'path=' +
      (params.path && params.path.length > 0 ? params.path : '/') +
      ';' +
      (location.protocol === 'https:' && params.secure && params.secure == true
        ? 'secure'
        : '');
  }
}

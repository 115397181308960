import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/shared/general.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { UserService } from 'src/app/shared/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-arabichome',
  templateUrl: './arabichome.component.html',
  styles: [
  ]
})
export class ArabichomeComponent implements OnInit {

  constructor(public service: GeneralService,public router:Router,public userservice : UserService, private notificationservice: NotificationService) { }
  Pictures_Url = environment.Pictures_Url;
  Logo_URL = "brand10_resized.webp";
  companies : any = [] ;
  ngOnInit(): void {
    this.check_language()
    this.load_companies()
  }
  check_language(){
    if(this.userservice.getCookie('lang') == 'en'){
      this.router.navigateByUrl('/main/home')
    }
  }
  load_companies() {
    this.service.user_home().subscribe(res => {
      this.companies = res;
    }, 
    err => { this.notificationservice.send_fail_message("خطاء في تحميل الشركة")})
  }
  //company management
  dialog_create_company(){
    this.service.dialog_arabic_create_company()
    .afterClosed().subscribe(res=>{
        if (res) {           
           this.load_companies();}
        else {  this.load_companies();}
      }
      , err => {this.load_companies();})
  }  
  dialog_update_company(company_id:number){
    this.service.dialog_arabic_update_company(company_id)
    .afterClosed().subscribe(res=>{
        if (res) {           
          this.notificationservice.send_success_message("تم تحديث شركتك"); this.load_companies();}
        else { this.notificationservice.send_fail_message("لم يتم تحديث شركتك"); this.load_companies();}
      }
      , err => { this.notificationservice.send_fail_message("خطاء في تحديث شركتك"); this.load_companies();})
  }
  dialog_view_company(company_id:number){
    this.service.dialog_arabic_view_company(company_id)
    .afterClosed().subscribe(res=>{
        if (res) {           
           this.load_companies();}
        else { this.load_companies();}
      }
      , err => { this.load_companies();})
  }
  dialog_add_company_photo(company_id:number){
    this.service.dialog_arabic_add_company_photo(company_id)
    .afterClosed().subscribe(res=>{
        if (res) {           
           this.load_companies();}
        else { }
      }
      , err => { })
  }
  dialog_view_company_photos(company_id:number){
    this.service.dialog_arabic_view_company_photos(company_id)
    .afterClosed().subscribe(res=>{
      }
      , err => { })
  }
  dialog_delete_company(company_id:number){
    this.service.openConfirmarabicDialog("هل تريد حذف شركتك؟")
    .afterClosed().subscribe(res=>{
        if (res) {   
          
          this.service.delete_company(company_id).subscribe(
        res=>{this.notificationservice.send_success_message("تم حذف شركتك"); this.load_companies();},
        err=>{this.notificationservice.send_fail_message("لم تم حذف شركتك."); this.load_companies();})
        }
          
        else { this.notificationservice.send_fail_message("لم تم حذف شركتك."); this.load_companies();}
      }
      , err => { this.notificationservice.send_fail_message("خطاء اثناء حذف شركتك."); this.load_companies();})
  }
  //branch management
  dialog_create_branch(company_Id){
    this.service.dialog_arabic_create_branch(company_Id)
    .afterClosed().subscribe(res=>{
        if (res) { 
           this.load_companies();
          }
        else { 
         this.load_companies();
        }
      }
      , err => { this.load_companies();})
  }  
  dialog_update_branch(branch_id:number){
    this.service.dialog_arabic_update_branch(branch_id)
    .afterClosed().subscribe(res=>{
        if (res) {
          this.load_companies();}
        else {this.load_companies();}
      }
      , err => { this.load_companies();})
  }
  dialog_view_branch(branch_id:number){
    this.service.dialog_arabic_view_branch(branch_id)
    .afterClosed().subscribe(res=>{
        if (res) {           
           this.load_companies();}
        else { this.load_companies();}
      }
      , err => { this.load_companies();})
  }
  dialog_delete_branch(branch_id:number){
    this.service.openConfirmarabicDialog("هل تريد حذف الفرع؟")
    .afterClosed().subscribe(res=>{
        if (res) {   
          this.service.delete_branch(branch_id).subscribe(
        res=>{this.notificationservice.send_success_message("تم حذف الفرع."); this.load_companies();},
        err=>{this.notificationservice.send_fail_message("لم يتم حذف الفرع."); this.load_companies();})
        }          
        else { this.notificationservice.send_fail_message("خطاء في حذف الفرع."); this.load_companies();}
      }
      , err => { this.notificationservice.send_fail_message("خطاء في حذف الفرع."); this.load_companies();})
  }
}

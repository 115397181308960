import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
// import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { GeneralService } from 'src/app/shared/general.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { AddOnlineTimeslotComponent } from '../add-online-timeslot/add-online-timeslot.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-extend-frontpage-ts',
  templateUrl: './extend-frontpage-ts.component.html',
  styles: [
  ]
})
export class ExtendFrontpageTsComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data, private notificationservice: NotificationService, public service: GeneralService, public FB: UntypedFormBuilder, public dialogRef: MatDialogRef<AddOnlineTimeslotComponent>) { }
  FG_Add_online_TS = this.FB.group({
    Point_Company_Consumption_No_Of_Points: [0, [Validators.required]],
    Point_Company_Consumption_No_Of_Time_Slots: [0, [Validators.required]],
    Point_Company_Consumption_No_months: [0, [Validators.required]],
    Point_Company_Consumption_Related_Company: [0, [Validators.required]],
  });
  ngOnInit(): void {
    this.FG_Add_online_TS.controls['Point_Company_Consumption_Related_Company'].setValue(this.data.company_id);
    this.FG_Add_online_TS.controls['Point_Company_Consumption_No_Of_Time_Slots'].setValue(this.data.no_time_slots);
  }
  disable_button = false;
  calculate() {
    this.FG_Add_online_TS.controls['Point_Company_Consumption_No_Of_Points'].setValue(this.FG_Add_online_TS.controls['Point_Company_Consumption_No_months'].value * this.FG_Add_online_TS.controls['Point_Company_Consumption_No_Of_Time_Slots'].value * this.data.single_product_online_price);
  }
  cancel() { this.dialogRef.close(false); }
  add(form) {
    if (form.valid) {
      if (this.FG_Add_online_TS.controls['Point_Company_Consumption_No_Of_Points'].value == 0)
      {
        this.notificationservice.send_fail_message("Number of slots and Number of Months must be greater than 0"); 
      }
      else if (this.FG_Add_online_TS.controls['Point_Company_Consumption_No_Of_Points'].value <= this.data.max_points_count) 
      {
        this.disable_button = true;
        var body = {
          "Point_Company_Consumption_No_Of_Points": +form.controls['Point_Company_Consumption_No_Of_Points'].value,
          "Point_Company_Consumption_No_Of_Time_Slots": +form.controls['Point_Company_Consumption_No_Of_Time_Slots'].value,
          "Point_Company_Consumption_No_months": +form.controls['Point_Company_Consumption_No_months'].value,
          "Point_Company_Consumption_Related_Company": +form.controls['Point_Company_Consumption_Related_Company'].value,
          "Point_Company_Consumption_Related_Time_Slot_Id": this.data.Time_Slot_Id,
          "Point_Company_Consumption_Related_Time_Slot_Id_Date": this.data.Time_Slot_Id_Date,
        }
        this.service.company_extend_front_TS(body).subscribe(res => { 
          this.disable_button = false;
          this.dialogRef.close(true); 
        }
          , err => { 
          this.disable_button = false;
          this.notificationservice.send_fail_message("Failed to add Online Time Slot"); 
          })
      }
      else
      {{ this.notificationservice.send_fail_message("The Company does not have enough Points to open the Slot"); }}
    }
    else { this.notificationservice.send_fail_message("Could not add Online Time Slot"); }
  }
}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioButton } from '@angular/material/radio';
// import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
// import { MatLegacyRadioButton as MatRadioButton } from '@angular/material/legacy-radio';
import { DomSanitizer } from '@angular/platform-browser';
import { GeneralService } from 'src/app/shared/general.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-company-photo',
  templateUrl: './add-company-photo.component.html',
  styles: [
  ]
})
export class AddCompanyPhotoComponent implements OnInit {
  @ViewChild('general')
  general1: MatRadioButton;
  @ViewChild('branch')
  branch1: MatRadioButton;
  @ViewChild('owner')
  owner1: MatRadioButton;
  // imgSrc: string = '../../../../../assets/Photos/Company_Photos/click here to upload.jpeg';
  initial_Pic_Name = '../../../../../assets/images/Companies_Id/brand10_resized.webp';
  selectedImg: any = null;

  constructor(public service: GeneralService, private notificationservice: NotificationService, @Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<AddCompanyPhotoComponent>, private sanitizer: DomSanitizer, public FB: UntypedFormBuilder) { }
  
  formGroup_Company_Picture_upload = this.FB.group({
    Company_Picture_Store_Name: ['', [Validators.required, Validators.maxLength(50)]],
    Company_Picture_Display_Name: ['', [Validators.required, Validators.maxLength(25)]],
    Company_Picture_Extention: ['', [Validators.required, Validators.maxLength(6)]],
    Company_Picture_Company: [0, Validators.required ],
    Company_Picture_Comment: ['', [Validators.maxLength(50)]],
    Company_Picture_Is_Owner_Identity: [0],
    Company_Picture_Is_Branch_Identity: [0],
    Dummy_Has_Photo : [false, [Validators.requiredTrue]],
    Dummy_Name_Already_Used : [true, [Validators.requiredTrue]],
    Dummy_Has_Pic_Type : [false, [Validators.requiredTrue]],
    });
  ngOnInit(): void {
    this.formGroup_Company_Picture_upload.controls['Company_Picture_Company'].setValue(this.data.Company_id);
    }

  showPreview(event: any) {
    if (event.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = (e: any) =>
        this.initial_Pic_Name = e.target.result;
      reader.readAsDataURL(event.target.files[0])
      this.selectedImg = event.target.files[0];
      this.formGroup_Company_Picture_upload.controls['Dummy_Has_Photo'].setValue(true);
    } else {
      this.initial_Pic_Name = '../../../../../assets/images/Companies_Id/brand10_resized.webp';
      this.selectedImg = null;
      this.formGroup_Company_Picture_upload.controls['Dummy_Has_Photo'].setValue(false);
    }
  }
  upload_photos(fg: UntypedFormGroup, Image_1: File) {
    this.formGroup_Company_Picture_upload.controls['Company_Picture_Store_Name'].setValue('pic1');
    this.formGroup_Company_Picture_upload.controls['Company_Picture_Extention'].setValue('Pic');
    this.formGroup_Company_Picture_upload.controls['Company_Picture_Is_Owner_Identity'].setValue(this.owner1.checked);
    this.formGroup_Company_Picture_upload.controls['Company_Picture_Is_Branch_Identity'].setValue(this.branch1.checked);    
    this.formGroup_Company_Picture_upload.controls['Company_Picture_Company'].setValue(this.data.company_id);
    if (this.formGroup_Company_Picture_upload.valid) {
      this.service.upload_company_image(this.formGroup_Company_Picture_upload.value, this.selectedImg).subscribe(
        res => {
          this.notificationservice.send_success_message('Picture Uploaded Successfully');
          this.closeDialog()
        }, err => {
          this.notificationservice.send_fail_message('Picture upload failed');
        });
    }
    else{this.notificationservice.send_fail_message('Data is not Valid');}
  }
  select_pic_cat() { this.formGroup_Company_Picture_upload.controls['Dummy_Has_Pic_Type'].setValue(true); }
  closeDialog() { this.dialogRef.close(); }
  
  update_photos(){
    if (this.formGroup_Company_Picture_upload.valid) {      
      this.formGroup_Company_Picture_upload.controls['Company_Picture_Is_Owner_Identity'].setValue(this.owner1.checked);
      this.formGroup_Company_Picture_upload.controls['Company_Picture_Is_Branch_Identity'].setValue(this.branch1.checked);

      this.service.Update_company_image(this.formGroup_Company_Picture_upload.value,false).subscribe(
        res => {
          this.notificationservice.send_success_message('Picture Updated Successfully');
        }, err => {
          this.notificationservice.send_fail_message('Picture update failed');
        });
    }
  }
}

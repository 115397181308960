import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/shared/general.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { InsertCompanyComponent } from '../../Companies/insert-company/insert-company.component';

@Component({
  selector: 'app-update-branch-arabic',
  templateUrl: './update-branch-arabic.component.html',
  styles: [
  ]
})
export class UpdateBranchArabicComponent implements OnInit {
  Branch_Id = this.data.branch_id;
  selected_city : number;
  selected_area : number;
  Cities: any [];
  Areas: any [];

  constructor(private router: Router, private http: HttpClient, public FB: UntypedFormBuilder,@Inject(MAT_DIALOG_DATA) public data, public service: GeneralService,public dialogRef: MatDialogRef<InsertCompanyComponent>, private notificationservice: NotificationService) { }
  FG_Branches = this.FB.group({
    Branch_Name: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(25)]],
    Branch_Name_Arabic: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(25)]],
    Branch_Description: ['', [Validators.required, Validators.maxLength(300)]],
    Branch_Description_Arabic: ['', [Validators.required, Validators.maxLength(300)]],
    Branch_Related_Company: [0, Validators.required],
    Branch_Note: ['', [Validators.maxLength(300)]],
    Branch_status: [0, Validators.required],
    Branch_Long: [{ value: 0, disabled: true }, [Validators.required, Validators.pattern("^\-?\\d+\\.\\d+"), Validators.min(-180), Validators.max(180)]],
    Branch_Lat: [{ value: 0, disabled: true }, [Validators.required, Validators.pattern("^\-?\\d+\\.\\d+"), Validators.min(-90), Validators.max(90)]],
    Is_valid_loc: [false, [Validators.requiredTrue]],
    Branch_Address: ['', [Validators.required, Validators.maxLength(80)]],
    Branch_Country: [0, Validators.required],
    Branch_City: [0, [Validators.required, Validators.min(1)]],
    Branch_Area: [0, [Validators.required, Validators.min(1)]],
    Branch_Phone_1: ['', [Validators.required, Validators.maxLength(25)]],
    Branch_Phone_2: ['', [Validators.maxLength(25)]],
    Branch_Phone_3: ['', [Validators.maxLength(25)]],
    Branch_Owner_Phone: ['', [Validators.maxLength(25)]],
    Branch_Is_Main_Branch: [true]
  });
  reset_FG_Branches() {
    this.FG_Branches.patchValue({
      $Branch_ID: 0,
      Branch_Name: '',
      Branch_Description: '',
      Branch_Related_Company: 0,
      Branch_Note: '',
      Branch_Admin_Note: '',
      Branch_Rate: '',
      Branch_status: 0,
      Branch_Long: 0,
      Branch_Lat: 0,
      Is_valid_loc: false,
      Branch_Address: '',
      Branch_Country: 0,
      Branch_City: 0,
      Branch_Area: 0,
      Branch_Updater: 0,
      Branch_Update_Time: '',
      Branch_Description_Arabic: '',
      Branch_Name_Arabic: '',
      Branch_Phone_1: '',
      Branch_Phone_2: '',
      Branch_Phone_3: '',
      Branch_Owner_Phone: '',
      Branch_Is_Main_Branch: true
    })
  }
  ngOnInit(): void {
    this.initiat_page();
  }
  initiat_page(){
    // this.FG_Branches.controls['Branch_Related_Company'].setValue(this.company_id);    
    this.service.List_Cites(1).subscribe(res => {
      this.Cities = res as [];
    }, err => { this.notificationservice.send_fail_message("Something went wrong") });
    this.reset_FG_Branches();
    this.load_branch();
  }
  cancel(){this.dialogRef.close(false);}  
  Confirm_location(x,y){
    if (x==0 || y==0 )
  {this.FG_Branches.controls['Is_valid_loc'].setValue(false);}
  else{this.FG_Branches.controls['Is_valid_loc'].setValue(true);}
}
  open_link() { this.service.open_location_link().afterClosed().subscribe(res => { 
    if (res.lat != 0 && res.lng !=0 ) {
      this.FG_Branches.controls['Branch_Lat'].setValue(res.lat);
    this.FG_Branches.controls['Branch_Long'].setValue(res.lng);
  }
  }) }
  change_city(test) {
    this.service.List_Areas(test).subscribe(res => {
      this.Areas = res as [];
    }, err => { this.notificationservice.send_fail_message("Something went wrong") });
  }
  change_area(test) {
    this.selected_area = test;
    this.FG_Branches.controls['Branch_Area'].setValue(test)    
  }
  load_branch(){
    //load branch    
    this.service.branch_Details(this.Branch_Id).subscribe((res :any)=>{
      this.FG_Branches.patchValue({
      $Branch_ID: res.branch_ID,
      Branch_Name: res.branch_Name,
      Branch_Description: res.branch_Description,
      Branch_Related_Company: res.branch_Related_Company,
      Branch_Note: res.branch_Note,
      Branch_Admin_Note: res.branch_Admin_Note,
      Branch_Rate: res.branch_Rate,
      Branch_status: res.branch_status,
      Branch_Long: res.branch_Long,
      Branch_Lat: res.branch_Lat,
      Branch_Address: res.branch_Address,
      // Branch_Area: res.branch_Area,
      Branch_Description_Arabic: res.branch_Description_Arabic,
      Branch_Name_Arabic: res.branch_Name_Arabic,
      Branch_Phone_1: res.branch_Phone_1,
      Branch_Phone_2: res.branch_Phone_2,
      Branch_Phone_3: res.branch_Phone_3,
      Branch_Owner_Phone: res.branch_Owner_Phone,
      Branch_Is_Main_Branch: res.branch_Is_Main_Branch
      })
      this.selected_city = res.area_Realated_City;
      this.selected_area = res.branch_Area;
      this.FG_Branches.controls['Branch_City'].setValue(this.selected_city)
      this.FG_Branches.controls['Branch_Area'].setValue(this.selected_area)
      this.change_city(this.selected_city);
    });
  }
  execute_edit(form_values: UntypedFormGroup){
    this.Confirm_location(form_values.controls['Branch_Lat'].value,form_values.controls['Branch_Long'].value)
    if (form_values.valid) {
      var body={
        "Branch_ID" :  this.Branch_Id,
        "Branch_Description" :  form_values.controls['Branch_Description'].value,
        "Branch_Note" : form_values.controls['Branch_Note'].value,
        "Branch_Long" : +form_values.controls['Branch_Long'].value,
        "Branch_Lat" : +form_values.controls['Branch_Lat'].value,
        "Branch_Address" : form_values.controls['Branch_Address'].value,
        "Branch_Area" : this.selected_area,
        "Branch_Description_Arabic" :form_values.controls['Branch_Description_Arabic'].value,
        "Branch_Phone_1" : form_values.controls['Branch_Phone_1'].value.toString(),
        "Branch_Phone_2" : form_values.controls['Branch_Phone_2'].value.toString(),
        "Branch_Phone_3" : form_values.controls['Branch_Phone_3'].value.toString(),
        "Branch_Owner_Phone" : form_values.controls['Branch_Owner_Phone'].value.toString(),
        "Branch_Is_Main_Branch" : form_values.controls['Branch_Is_Main_Branch'].value
      };
      this.service.update_branch(this.Branch_Id,body).subscribe(res=>{
        this.notificationservice.send_success_message("Branch updated");this.dialogRef.close(true);},
        err=>{this.notificationservice.send_fail_message("Could not create the branch.");this.initiat_page();})
    }else{this.notificationservice.send_fail_message("Branch Could not be created") ;this.initiat_page();}
  }

}

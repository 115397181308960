import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/shared/general.service';
import { NotificationService } from 'src/app/shared/notification.service';

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styles: [
  ]
})
export class BranchesComponent implements OnInit {
  branches : any = [] ;

  constructor(public service: GeneralService, private notificationservice: NotificationService) { }

  ngOnInit(): void {
    this.load_branches();
  }

  load_branches() {
    this.service.user_branches().subscribe(res => {
      this.branches = res;
    }, 
    err => { this.notificationservice.send_fail_message("Failed to Your Branches")})
  }
}

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { GeneralService } from 'src/app/shared/general.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-arabic-update-product',
  templateUrl: './arabic-update-product.component.html',
  styles: [
  ]
})
export class ArabicUpdateProductComponent implements OnInit {

  constructor(public FB: UntypedFormBuilder, public notificationService: NotificationService,private router : Router,private notificationservice: NotificationService,private currentRoute: ActivatedRoute, private service: GeneralService) { }
  Pictures_Url = environment.Pictures_Url
  product_id = 0; product_id_date = 0;  
  Cat_1_list : any =[];Cat_2_list : any =[];Cat_3_list: any =[];Cat_4_list : any =[];
  cat_1_selected = 0;cat_2_selected = 0;cat_3_selected = 0;cat_4_selected = 0;
  prod_delivery_statuses : any =[];  
  product_Color_Id: number=0;product_Color_Id_Date:number =0;
  picPath = ""; If_has_pic = false;
  product_Company_Id = 0; product_Branch_Id = 0;
  product_colors: any = []; product_pictures: any = [];
  
  ngOnInit(): void {
    this.service.product_delivery_Statuses().subscribe(res=>{this.prod_delivery_statuses = res;});
    this.getCurrentProduct()
  }
  formGroup_insert_Archived_Products = this.FB.group({
    Archived_Product_Id:  [0, [Validators.required, Validators.min(1)]],
    Archived_Product_Id_Date:  [0, [Validators.required, Validators.min(1)]],
    Archived_Product_Name: ['', [Validators.required, Validators.maxLength(50)]],
    Archived_Product_Name_Arabic: ['', [Validators.required, Validators.maxLength(50)]],
    product_status:[{value:'', disabled:true}],
    Archived_Product_Description: ['', [Validators.required, Validators.maxLength(300)]],
    Archived_Product_Description_Arabic: ['', [Validators.maxLength(300)]],
    cat_1: [0, [Validators.required, Validators.min(1)]],
    cat_2: [0, [Validators.required, Validators.min(1)]],
    cat_3: [0, [Validators.required, Validators.min(1)]],
    Archived_Product_Related_Catigory_4: [0, [Validators.required, Validators.min(1)]],
    Archived_Product_Related_Branch: [0, [Validators.required, Validators.min(1)]],
    Archived_Products_Price: [0, [Validators.required, Validators.min(1)]],
    Archived_Product_Note: ['', [Validators.maxLength(300)]],
    Archived_Products_Delivery_Status:[0, [Validators.required, Validators.min(1)]],
    Archived_Product_Company_Delivery_Point: [0, [Validators.required, Validators.min(0)]],
    Archived_Product_User_Delivery_Point: [0, [Validators.required, Validators.min(0)]],
  });
  reset_formGroup_insert_Archived_Products() {
    this.formGroup_insert_Archived_Products.patchValue({
      Archived_Product_Id:0,
      Archived_Product_Id_Date : 0,
      Archived_Product_Name: '',
      Archived_Product_Name_Arabic: '',
      Archived_Product_Description: '',
      Archived_Product_Description_Arabic: '',
      Archived_Product_Related_Catigory_4: 0,
      Archived_Product_Note: '',
      Archived_Product_Company_Delivery_Point: 0,
      Archived_Product_User_Delivery_Point: 0,
      Archived_Products_Delivery_Status:0,
    });
  }
  cat_1_change(cat_1){
    this.cat_1_selected = cat_1.value;    
    this.refresh_cat_2();
    // this.cat_2_selected = 0;this.cat_3_selected = 0;this.cat_4_selected = 0;
   }
  refresh_cat_2(){
    if(this.cat_1_selected==0){
      this.Cat_2_list = [];this.Cat_3_list = [];this.Cat_4_list = [];
    }
    else{this.service.get_catigory_2_list_min(this.cat_1_selected).subscribe(res=>{
      this.Cat_2_list = res;this.Cat_3_list = [];this.Cat_4_list = [];
    }
      ,err=>{this.notificationService.send_fail_message("Failed to load")})}
  }
  cat_2_change(cat_2){this.cat_2_selected = cat_2.value;
    this.refresh_cat_3();
    // this.cat_3_selected = 0;this.cat_4_selected = 0;
  }
  refresh_cat_3(){
    if(this.cat_2_selected==0){this.Cat_3_list = [];this.Cat_4_list = [];}
    else{this.service.get_catigory_3_list_min(this.cat_2_selected).subscribe(res=>{
      this.Cat_3_list = res;this.Cat_4_list = [];
    }
      ,err=>{this.notificationService.send_fail_message("Failed to load")})}
  }
  cat_3_change(cat_3){this.cat_3_selected = cat_3.value;
     this.refresh_cat_4();
    //  this.cat_4_selected = 0;
  }
  refresh_cat_4(){
    if(this.cat_3_selected==0){this.Cat_4_list = [];}
    else{this.service.get_catigory_4_list_min(this.cat_3_selected).subscribe(res=>{
      this.Cat_4_list = res;
    }
      ,err=>{this.notificationService.send_fail_message("Failed to load")})}
  }
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 300,
    margin: 20,
    autoHeight : true,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 3
      },
      500: {
        items: 3
      },
      740: {
        items: 3
      },
      940: {
        items: 5
      }
    },
    nav: true
  }
  changeImg(img: string) {
    this.If_has_pic = true
    this.picPath = img;
  }
  getCurrentProduct() {
    this.product_id = +this.currentRoute.snapshot.paramMap.get('id');
    this.product_id_date = +this.currentRoute.snapshot.paramMap.get('id_date');
    this.reset_formGroup_insert_Archived_Products();
    if (this.product_id && this.product_id_date) {
      this.service.load_product(this.product_id, this.product_id_date).subscribe((res: any) => {
        // console.log(res.product_Colors_Summary)
        this.cat_1_selected = res.product_Data.product_Catigory_1_Id
         this.cat_2_selected = res.product_Data.product_Catigory_2_Id
         this.cat_3_selected = res.product_Data.product_Catigory_3_Id
        
         this.service.get_catigory_1_list_min().subscribe(res => this.Cat_1_list = res );
         this.service.get_catigory_2_list_min(this.cat_1_selected).subscribe(res=>{this.Cat_2_list = res;});
         this.service.get_catigory_3_list_min(this.cat_2_selected).subscribe(res=>{this.Cat_3_list = res;});
         this.service.get_catigory_4_list_min(this.cat_3_selected).subscribe(res=>{this.Cat_4_list = res;});

        this.formGroup_insert_Archived_Products.patchValue({
          Archived_Product_Id: this.product_id,
          Archived_Product_Id_Date: this.product_id_date,
          Archived_Product_Name: res.product_Data.product_Name,
          Archived_Product_Description: res.product_Data.product_Description,
          Archived_Product_Name_Arabic: res.product_Data.product_Name_Arabic,
          Archived_Product_Description_Arabic: res.product_Data.product_Description_Arabic,
          Archived_Product_Note: res.product_Data.product_Note,
          Archived_Product_Related_Branch: res.product_Data.product_Related_Branch,
          product_status : res.product_Data.products_Status_name_arabic,

          cat_1 : res.product_Data.product_Catigory_1_Id,
          cat_2 : res.product_Data.product_Catigory_1_Id,
          cat_3 : res.product_Data.product_Catigory_3_Id,
          Archived_Product_Related_Catigory_4: res.product_Data.product_Related_Catigory_4 ,
          
          Archived_Products_Price: res.product_Data.product_Price,
          Archived_Product_User_Delivery_Point: res.product_Data.product_User_Delivery_Point ,
          Archived_Product_Company_Delivery_Point: res.product_Data.product_Company_Delivery_Point ,
          Archived_Products_Delivery_Status: res.product_Data.products_Delivery_Status ,
         });         
         
        if(res.product_Data.product_Pic_path != null)
        {this.If_has_pic = true 
          this.picPath = res.product_Data.product_Pic_path
        }
        else{this.If_has_pic = false
        }
        this.product_colors = res.product_Colors_Summary
        this.product_pictures = res.product_Pictures_Summary
        this.changeColor(res.product_Colors_Summary[0].product_Color_Id,res.product_Colors_Summary[0].product_Color_Id_Date)
        this.product_Company_Id = res.product_Data.product_Company_Id 
        this.product_Branch_Id = res.product_Data.product_Related_Branch 
      })      
    }
  }
  changeColor(product_Color_Id: number,product_Color_Id_Date:number) {
    if (product_Color_Id && product_Color_Id_Date) {
      this.product_Color_Id  = product_Color_Id; this.product_Color_Id_Date = product_Color_Id_Date;
      this.service.load_product_color_pictures(product_Color_Id, product_Color_Id_Date).subscribe((res: any) => {
        if (res.length >0)
        {
          this.product_pictures = res
          this.changeImg(res[0].product_Picture_Store_Name)
        }
        else
        {
          this.product_pictures = []
          this.If_has_pic = false
        }

      })
  }}
  delete_product(){
    this.service.openConfirmDialog("Are you sure you want to delete the Product?")
    .afterClosed().subscribe(res=>{
        if (res) {   
          
          this.service.delete_product(this.product_id,this.product_id_date).subscribe(
        res=>{this.notificationservice.send_success_message("Product Deleted"); 
        this.router.navigate(['/main/products_management']);},
        err=>{this.notificationservice.send_fail_message("Could not Delete the Product."); })
        }
          
        else { this.notificationservice.send_fail_message("Your Product is not Deleted"); }
      }
      , err => { this.notificationservice.send_fail_message("Could not Delete Your Product");})
  }
  Update_Product(formGroup_Create_Company: UntypedFormGroup){
    if (formGroup_Create_Company.valid) {
    this.service.update_Archived_Products(this.product_id,this.product_id_date , formGroup_Create_Company.getRawValue())
    .subscribe(res=>{
      this.getCurrentProduct();this.notificationservice.send_success_message("Product Updated");}
      ,err=>{
        this.getCurrentProduct(); this.notificationservice.send_fail_message("Product Update Failed");})
      }
      else{this.notificationService.send_fail_message("Product Could not be Updated") ;}
    }
  addColor(){
    this.service.dialog_arabic_add_product_color(this.product_id,this.product_id_date)
    .afterClosed().subscribe(res=>{
        if (res) {           
           this.getCurrentProduct();}
        else {  this.getCurrentProduct();}
      }
      , err => {this.getCurrentProduct();})
  }  
  dialog_update_Color(product_Color_Id,product_Color_Id_Date){
    this.service.dialog_arabic_update_product_color(product_Color_Id,product_Color_Id_Date)
    .afterClosed().subscribe(res=>{
        if (res) {           
          this.getCurrentProduct();}
        else {  this.getCurrentProduct();}
      }
      , err => { this.notificationservice.send_fail_message("Could not update Your Product Color"); this.getCurrentProduct();})
  }
  dialog_update_Picture(picture_id,Picture_Id_Date){
    this.service.dialog_arabic_insert_update_product_picture(picture_id,Picture_Id_Date,true)
    .afterClosed().subscribe(res=>{
        if (res) {           
          this.getCurrentProduct();}
        else {  this.getCurrentProduct();}
      }
      , err => { this.notificationservice.send_fail_message("Could not update Your Product Picture"); this.getCurrentProduct();})
  }
  dialog_add_Picture(product_Color_Id,product_Color_Id_Date){
    this.service.dialog_arabic_insert_update_product_picture(this.product_Color_Id,this.product_Color_Id_Date,false)
    .afterClosed().subscribe(res=>{
        if (res) {           
          this.getCurrentProduct();}
        else {  this.getCurrentProduct();}
      }
      , err => { this.notificationservice.send_fail_message("Could not Add Your Product Picture"); this.getCurrentProduct();})
  }
}

import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
// import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/shared/general.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { InsertCompanyComponent } from '../../Companies/insert-company/insert-company.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-view-branch',
  templateUrl: './view-branch.component.html',
  styles: [
  ]
})
export class ViewBranchComponent implements OnInit {
  Branch_Id = this.data.branch_id;
  selected_city : number;
  selected_area : number;
  Cities: any [];
  Areas: any [];

  constructor(private router: Router, private http: HttpClient, public FB: UntypedFormBuilder,@Inject(MAT_DIALOG_DATA) public data, public service: GeneralService,public dialogRef: MatDialogRef<InsertCompanyComponent>, private notificationservice: NotificationService) { }
  FG_Branches = this.FB.group({
    Branch_Name: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(25)]],
    Branch_Name_Arabic: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(25)]],
    Branch_Description: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(300)]],
    Branch_Description_Arabic: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(300)]],
    Branch_Related_Company: [0, Validators.required],
    Branch_Note: [{ value: '', disabled: true }, [Validators.maxLength(300)]],
    Branch_Long: [{ value: 0, disabled: true }, [Validators.required, Validators.pattern("^\-?\\d+\\.\\d+"), Validators.min(-180), Validators.max(180)]],
    Branch_Lat: [{ value: 0, disabled: true }, [Validators.required, Validators.pattern("^\-?\\d+\\.\\d+"), Validators.min(-90), Validators.max(90)]],
    Is_valid_loc: [{ value: true, disabled: true }, [Validators.requiredTrue]],
    Branch_Address: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(80)]],
    Branch_Country: [{ value: 0, disabled: true }, Validators.required],
    Branch_City: [{ value: 0, disabled: true }, [Validators.required, Validators.min(1)]],
    Branch_Area: [{ value: 0, disabled: true }, [Validators.required, Validators.min(1)]],
    Branch_Phone_1: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(25)]],
    Branch_Phone_2: [{ value: '', disabled: true }, [Validators.maxLength(25)]],
    Branch_Phone_3: [{ value: '', disabled: true }, [Validators.maxLength(25)]],
    Branch_Owner_Phone: [{ value: '', disabled: true }, [Validators.maxLength(25)]],
    Branch_Is_Main_Branch: [false]
  });
  reset_FG_Branches() {
    this.FG_Branches.patchValue({
      $Branch_ID: 0,
      Branch_Name: '',
      Branch_Description: '',
      Branch_Related_Company: 0,
      Branch_Note: '',
      Branch_Admin_Note: '',
      Branch_Rate: '',
      Branch_status: 0,
      Branch_Long: 0,
      Branch_Lat: 0,
      Is_valid_loc: false,
      Branch_Address: '',
      Branch_Country: 0,
      Branch_City: 0,
      Branch_Area: 0,
      Branch_Updater: 0,
      Branch_Update_Time: '',
      Branch_Description_Arabic: '',
      Branch_Name_Arabic: '',
      Branch_Phone_1: '',
      Branch_Phone_2: '',
      Branch_Phone_3: '',
      Branch_Owner_Phone: '',
      Branch_Is_Main_Branch: true
    })
  }
  ngOnInit(): void {
    this.initiat_page();
  }
  initiat_page(){
    // this.FG_Branches.controls['Branch_Related_Company'].setValue(this.company_id);    
    this.service.List_Cites(1).subscribe(res => {
      this.Cities = res as [];
    }, err => { this.notificationservice.send_fail_message("Something went wrong") });
    this.reset_FG_Branches();
    this.load_branch();
  }
  cancel(){this.dialogRef.close(false);}  
  load_branch(){
    //load branch    
    this.service.branch_Details(this.Branch_Id).subscribe((res :any)=>{
      this.FG_Branches.patchValue({
      $Branch_ID: res.branch_ID,
      Branch_Name: res.branch_Name,
      Branch_Description: res.branch_Description,
      Branch_Related_Company: res.branch_Related_Company,
      Branch_Note: res.branch_Note,
      Branch_Admin_Note: res.branch_Admin_Note,
      Branch_Rate: res.branch_Rate,
      Branch_status: res.branch_status,
      Branch_Long: res.branch_Long,
      Branch_Lat: res.branch_Lat,
      Branch_Address: res.branch_Address,
      // Branch_Area: res.branch_Area,
      Branch_Description_Arabic: res.branch_Description_Arabic,
      Branch_Name_Arabic: res.branch_Name_Arabic,
      Branch_Phone_1: res.branch_Phone_1,
      Branch_Phone_2: res.branch_Phone_2,
      Branch_Phone_3: res.branch_Phone_3,
      Branch_Owner_Phone: res.branch_Owner_Phone,
      Branch_Is_Main_Branch: res.branch_Is_Main_Branch
      })
      this.selected_city = res.area_Realated_City;
      this.selected_area = res.branch_Area;
      this.FG_Branches.controls['Branch_City'].setValue(this.selected_city)
      this.FG_Branches.controls['Branch_Area'].setValue(this.selected_area)
      this.change_city(this.selected_city);
    });
  }
  change_city(test) {
    this.service.List_Areas(test).subscribe(res => {
      this.Areas = res as [];
    }, err => { this.notificationservice.send_fail_message("Something went wrong") });
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
// import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {MatDialog} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AddFrontpageTimeslotComponent } from '../Dialog/add-frontpage-timeslot/add-frontpage-timeslot.component';
import { AddOnlineTimeslotComponent } from '../Dialog/add-online-timeslot/add-online-timeslot.component';
import { DialogCustomConfirmComponent } from '../Dialog/dialog-custom-confirm/dialog-custom-confirm.component';
import { ExtendFrontpageTsComponent } from '../Dialog/extend-frontpage-ts/extend-frontpage-ts.component';
import { ExtendOnlineTsComponent } from '../Dialog/extend-online-ts/extend-online-ts.component';
import { DialogLinkComponent } from '../Dialog/locations/dialog-link/dialog-link.component';
import { DialogManualComponent } from '../Dialog/locations/dialog-manual/dialog-manual.component'
import { InsertBranchComponent } from '../Pages/Branches/insert-branch/insert-branch.component';
import { UpdateBranchComponent } from '../Pages/Branches/update-branch/update-branch.component';
import { ViewBranchComponent } from '../Pages/Branches/view-branch/view-branch.component';
import { AddCompanyPhotoComponent } from '../Pages/Companies/add-company-photo/add-company-photo.component'
import { InsertCompanyComponent } from '../Pages/Companies/insert-company/insert-company.component';
import { UpdateCompanyComponent } from '../Pages/Companies/update-company/update-company.component';
import { ViewCompanyComponent } from '../Pages/Companies/view-company/view-company.component';
import { ViewCompanyPhotosComponent } from '../Pages/Companies/view-company-photos/view-company-photos.component'
import { DialogUpdateProductColorarabicComponent } from '../Pages/product_colors/dialog-update-product-colorarabic/dialog-update-product-colorarabic.component';
import { DialogUpdateProductColorComponent } from '../Pages/product_colors/dialog-update-product-color/dialog-update-product-color.component';
import { DialogAddProductColorComponent } from '../Pages/product_colors/dialog-add-product-color/dialog-add-product-color.component';
import { DialogAddProductColorarabicComponent } from '../Pages/product_colors/dialog-add-product-colorarabic/dialog-add-product-colorarabic.component';
import { DialogAddEditProductPicturesComponent } from '../Pages/Products/dialog-add-edit-product-pictures/dialog-add-edit-product-pictures.component';
import { DialogArabicCustomConfirmComponent } from '../Dialog/dialog-custom-confirm/dialog-arabic-custom-confirm/dialog-arabic-custom-confirm.component';
import { AddArabicOnlineTimeslotComponent } from '../Dialog/add-online-timeslot/add-arabic-online-timeslot/add-arabic-online-timeslot.component';
import { AddArabicFrontpageTimeslotComponent } from '../Dialog/add-frontpage-timeslot/add-arabic-frontpage-timeslot/add-arabic-frontpage-timeslot.component';
import { ExtendArabicFrontpageTsComponent } from '../Dialog/extend-frontpage-ts/extend-arabic-frontpage-ts/extend-arabic-frontpage-ts.component';
import { ExtendArabicOnlineTsComponent } from '../Dialog/extend-online-ts/extend-arabic-online-ts/extend-arabic-online-ts.component';
import { DialogArabicLinkComponent } from '../Dialog/locations/dialog-link/dialog-arabic-link/dialog-arabic-link.component';
import { InsertCompanyArabicComponent } from '../Pages/Companies/insert-company-arabic/insert-company-arabic.component';
import { UpdateCompanyArabicComponent } from '../Pages/Companies/update-company-arabic/update-company-arabic.component';
import { ViewCompanyArabicComponent } from '../Pages/Companies/view-company-arabic/view-company-arabic.component';
import { AddCompanyArabicPhotoComponent } from '../Pages/Companies/add-company-photo/add-company-arabic-photo/add-company-arabic-photo.component';
import { ViewCompanyPhotosArabicComponent } from '../Pages/Companies/view-company-photos/view-company-photos-arabic/view-company-photos-arabic.component';
import { InsertBranchArabicComponent } from '../Pages/Branches/insert-branch-arabic/insert-branch-arabic.component';
import { UpdateBranchArabicComponent } from '../Pages/Branches/update-branch-arabic/update-branch-arabic.component';
import { ViewBranchArabicComponent } from '../Pages/Branches/view-branch-arabic/view-branch-arabic.component';
import { DialogAddEditProductPicturesArabicComponent } from '../Pages/Products/dialog-add-edit-product-pictures/dialog-add-edit-product-pictures-arabic/dialog-add-edit-product-pictures-arabic.component';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  url = environment.BaseUrl + 'General';

  constructor(public FB: UntypedFormBuilder,private http: HttpClient, private dialog: MatDialog) { }
  user_home(): Observable<any> {return this.http.get<any>(this.url + '/user_home');}
  user_branches(): Observable<any> {return this.http.get<any>(this.url + '/user_branches');}
  load_companies(): Observable<any> {return this.http.get<any>(this.url + '/load_companies');}
  load_one_company(company_id : number): Observable<any> {return this.http.get<any>(this.url + '/load_one_company/' + company_id);}

  //points management
  company_add_display_TS(formData){
    return this.http.post(environment.BaseUrl+'General/company_add_display_TS',formData);
  }
  company_add_front_TS(formData){
    return this.http.post(environment.BaseUrl+'General/company_add_front_TS',formData);
  }
  company_extend_display_TS(formData){
    return this.http.post(environment.BaseUrl+'General/company_extend_display_TS',formData);
  }
  company_extend_front_TS(formData){
    return this.http.post(environment.BaseUrl+'General/company_extend_front_TS',formData);
  }
  put_product_online(formData){
    return this.http.post(environment.BaseUrl+'General/put_product_online',formData);
  }
  put_product_offline(formData){
    return this.http.post(environment.BaseUrl+'General/put_product_offline',formData);
  }
  put_product_onfrontpage(formData){
    return this.http.post(environment.BaseUrl+'General/put_product_onfrontpage',formData);
  }
  remove_product_onfrontpage(formData){
    return this.http.post(environment.BaseUrl+'General/remove_product_onfrontpage',formData);
  }
  // dialog confirm box
  openConfirmDialog(msg) {
    return this.dialog.open(DialogCustomConfirmComponent, {
      width: '390px;',
      position: { top: "10px" },
      disableClose: true,
      data: { message: msg }
    });
  }
  openConfirmarabicDialog(msg) {
    return this.dialog.open(DialogArabicCustomConfirmComponent, {
      width: '390px;',
      position: { top: "10px" },
      disableClose: true,
      data: { message: msg }
    });
  }
  dialog_add_online_timeslot(company_id:number,max_points_count:number, single_product_online_price : number) {
    return this.dialog.open(AddOnlineTimeslotComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {company_id:company_id,max_points_count:max_points_count, single_product_online_price: single_product_online_price}
    });
  }
  dialog_company_add_front_TS(company_id:number,max_points_count:number, single_product_online_price : number) {
    return this.dialog.open(AddFrontpageTimeslotComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {company_id:company_id,max_points_count:max_points_count, single_product_online_price: single_product_online_price}
    });
  }
  dialog_extend_online_timeslot(company_id:number,max_points_count:number, single_product_online_price : number, Time_Slot_Id:number, Time_Slot_Id_Date:number,no_time_slots: number) {
    return this.dialog.open(ExtendOnlineTsComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {company_id:company_id,max_points_count:max_points_count, single_product_online_price: single_product_online_price, Time_Slot_Id:Time_Slot_Id, Time_Slot_Id_Date:Time_Slot_Id_Date,no_time_slots: no_time_slots}
    });
  }
  dialog_company_extend_front_TS(company_id:number,max_points_count:number, single_product_online_price : number, Time_Slot_Id:number, Time_Slot_Id_Date:number, no_time_slots:number) {
    return this.dialog.open(ExtendFrontpageTsComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {company_id:company_id,max_points_count:max_points_count, single_product_online_price: single_product_online_price, Time_Slot_Id:Time_Slot_Id, Time_Slot_Id_Date:Time_Slot_Id_Date,no_time_slots: no_time_slots}
    });
  }

  //dialog company management
  dialog_create_company() {
    return this.dialog.open(InsertCompanyComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh'
    });
  }
  dialog_update_company(company_id:number) {
    return this.dialog.open(UpdateCompanyComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {company_id:company_id}
    });
  }
  dialog_view_company(company_id:number) {
    return this.dialog.open(ViewCompanyComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {company_id:company_id}
    });
  }
  dialog_add_company_photo(company_id:number) {
    return this.dialog.open(AddCompanyPhotoComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {company_id:company_id}
    });
  }
  dialog_view_company_photos(company_id:number) {
    return this.dialog.open(ViewCompanyPhotosComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {company_id:company_id}
    });
  }
  //dialog Branch management
  dialog_create_branch(company_id:number) {
    return this.dialog.open(InsertBranchComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {company_id:company_id}
    });
  }
  dialog_update_branch(branch_id:number) {
    return this.dialog.open(UpdateBranchComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {branch_id:branch_id}
    });
  }
  dialog_view_branch(branch_id:number) {
    return this.dialog.open(ViewBranchComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {branch_id:branch_id}
    });
  } 
  open_location_link() {
    return this.dialog.open(DialogLinkComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh'
    });
  }
  //dialog product color
  dialog_add_product_color(product_id:number,product_id_date:number) {
    return this.dialog.open(DialogAddProductColorComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {product_id:product_id,product_id_date:product_id_date}
    });
  }
  dialog_update_product_color(product_color_id:number,product_color_id_date:number) {
    return this.dialog.open(DialogUpdateProductColorComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {product_color_id:product_color_id,product_color_id_date:product_color_id_date}
    });
  }
  //dialog upload/update product picture
  dialog_insert_update_product_picture(product_picture_id:number,product_picture_id_date:number,If_Update_Picture : boolean) {
    return this.dialog.open(DialogAddEditProductPicturesComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {product_picture_id:product_picture_id,product_picture_id_date:product_picture_id_date,If_Update_Picture:If_Update_Picture}
    });
  }

  // arabic dialog
  // dialog confirm box
  openConfirmDialog_arabic(msg) {
    return this.dialog.open(DialogArabicCustomConfirmComponent, {
      width: '390px;',
      position: { top: "10px" },
      disableClose: true,
      data: { message: msg }
    });
  }
  //dialog time slots
  dialog_arabic_add_online_timeslot(company_id:number,max_points_count:number, single_product_online_price : number) {
    return this.dialog.open(AddArabicOnlineTimeslotComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {company_id:company_id,max_points_count:max_points_count, single_product_online_price: single_product_online_price}
    });
  }
  dialog_arabic_company_add_front_TS(company_id:number,max_points_count:number, single_product_online_price : number) {
    return this.dialog.open(AddArabicFrontpageTimeslotComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {company_id:company_id,max_points_count:max_points_count, single_product_online_price: single_product_online_price}
    });
  }
  dialog_arabic_extend_online_timeslot(company_id:number,max_points_count:number, single_product_online_price : number, Time_Slot_Id:number, Time_Slot_Id_Date:number,no_time_slots: number) {
    return this.dialog.open(ExtendArabicOnlineTsComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {company_id:company_id,max_points_count:max_points_count, single_product_online_price: single_product_online_price, Time_Slot_Id:Time_Slot_Id, Time_Slot_Id_Date:Time_Slot_Id_Date,no_time_slots: no_time_slots}
    });
  }
  dialog_arabic_company_extend_front_TS(company_id:number,max_points_count:number, single_product_online_price : number, Time_Slot_Id:number, Time_Slot_Id_Date:number, no_time_slots:number) {
    return this.dialog.open(ExtendArabicFrontpageTsComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {company_id:company_id,max_points_count:max_points_count, single_product_online_price: single_product_online_price, Time_Slot_Id:Time_Slot_Id, Time_Slot_Id_Date:Time_Slot_Id_Date,no_time_slots: no_time_slots}
    });
  }

  //dialog company management
  dialog_arabic_create_company() {
    return this.dialog.open(InsertCompanyArabicComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh'
    });
  }
  dialog_arabic_update_company(company_id:number) {
    return this.dialog.open(UpdateCompanyArabicComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {company_id:company_id}
    });
  }
  dialog_arabic_view_company(company_id:number) {
    return this.dialog.open(ViewCompanyArabicComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {company_id:company_id}
    });
  }
  dialog_arabic_add_company_photo(company_id:number) {
    return this.dialog.open(AddCompanyArabicPhotoComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {company_id:company_id}
    });
  }
  dialog_arabic_view_company_photos(company_id:number) {
    return this.dialog.open(ViewCompanyPhotosArabicComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {company_id:company_id}
    });
  }
  //dialog Branch management
  dialog_arabic_create_branch(company_id:number) {
    return this.dialog.open(InsertBranchArabicComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {company_id:company_id}
    });
  }
  dialog_arabic_update_branch(branch_id:number) {
    return this.dialog.open(UpdateBranchArabicComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {branch_id:branch_id}
    });
  }
  dialog_arabic_view_branch(branch_id:number) {
    return this.dialog.open(ViewBranchArabicComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {branch_id:branch_id}
    });
  } 
  open_arabic_location_link() {
    return this.dialog.open(DialogArabicLinkComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh'
    });
  }
  //dialog product color
  dialog_arabic_add_product_color(product_id:number,product_id_date:number) {
    return this.dialog.open(DialogAddProductColorarabicComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {product_id:product_id,product_id_date:product_id_date}
    });
  }
  dialog_arabic_update_product_color(product_color_id:number,product_color_id_date:number) {
    return this.dialog.open(DialogUpdateProductColorarabicComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {product_color_id:product_color_id,product_color_id_date:product_color_id_date}
    });
  }
  //dialog upload/update product picture
  dialog_arabic_insert_update_product_picture(product_picture_id:number,product_picture_id_date:number,If_Update_Picture : boolean) {
    return this.dialog.open(DialogAddEditProductPicturesArabicComponent, {
      width: '90vw',
      position: { top: "5vh" },
      disableClose: true,
      height: '90vh',
      data: {product_picture_id:product_picture_id,product_picture_id_date:product_picture_id_date,If_Update_Picture:If_Update_Picture}
    });
  }

  //companies management
  create_company(formData){
    return this.http.post(environment.BaseUrl+'General/create_company',formData);
  }
  company_Details(company_Id) {
    return this.http.get(environment.BaseUrl + 'General/company_Details/' + company_Id);
  }
  update_company(company_Id, body) {
    return this.http.post(environment.BaseUrl + 'General/update_company/' + company_Id, body);
  }
  delete_company(company_Id) {
    return this.http.get(environment.BaseUrl + 'General/delete_company/' + company_Id);
  }
  upload_company_image (body,Image : File){
    const formData : FormData= new FormData();
    formData.append('Image',Image );
    formData.append('ImageData',JSON.stringify(body));
    return this.http.post(environment.BaseUrl + 'General/upload_company_image/' ,formData);
  }
  Load_Pic_ID(Pic_Id : number, company_id:number){
    return this.http.get(environment.BaseUrl + 'General/Load_Pic/'+Pic_Id + '/' + company_id);
  }
  Update_company_image (update_data,To_Delete){
    let x = update_data.$Company_Picture_Id;
  let body =({
    "Company_Picture_Id":+update_data.$Company_Picture_Id,
    "Company_Picture_Display_Name":update_data.Company_Picture_Display_Name,
    "Company_Picture_Comment": update_data.Company_Picture_Comment,
    "Company_Picture_Is_Owner_Identity": update_data.Company_Picture_Is_Owner_Identity,
    "Company_Picture_Is_Branch_Identity": update_data.Company_Picture_Is_Branch_Identity,
    "Company_Picture_Is_Deleted": To_Delete
  });    
  //console.log(body);
   return this.http.post(environment.BaseUrl + 'CompaniesManagment/Update_Company_Pic/' + x ,body);
  }
  companies_identites(company_Id) {
    return this.http.get(environment.BaseUrl + 'General/companies_identites/' + company_Id);
  }
  //Branches Management  
  Create_Branch(body) {
    return this.http.post(environment.BaseUrl + 'General/create_branch', body);
  }
  branch_Details(branch_Id) {
    return this.http.get(environment.BaseUrl + 'General/branch_Details/' + branch_Id);
  }
  update_branch(branch_Id, body) {
    return this.http.post(environment.BaseUrl + 'General/update_branch/' + branch_Id, body);
  }
  delete_branch(branch_Id) {
    return this.http.get(environment.BaseUrl + 'General/delete_branch/' + branch_Id);
  }

  //semi permentants
  List_Cites(Country_Id) {
    return this.http.get(environment.BaseUrl + 'General/get_cities/'+Country_Id);
  }
  List_Areas(City_Id) {
    return this.http.get(environment.BaseUrl + 'General/get_areas/'+City_Id);
  }  
  get_catigory_1_list_min() {
    return this.http.get(environment.BaseUrl + 'General/get_catigory_1_list_min');
  }
  get_catigory_2_list_min(related_cat_1 : number) {
    return this.http.get(environment.BaseUrl + 'General/get_catigory_2_list_min/' + related_cat_1);
  }
  get_catigory_3_list_min(related_cat_2 : number) {
    return this.http.get(environment.BaseUrl + 'General/get_catigory_3_list_min/' + related_cat_2 );
  }
  get_catigory_4_list_min(related_cat_3 : number) {
    return this.http.get(environment.BaseUrl + 'General/get_catigory_4_list_min/' + related_cat_3);
  }
  product_delivery_Statuses() {
    return this.http.get(environment.BaseUrl + 'General/product_Delivery_Statuses');
  }  
  // products management
  user_companies() {
    return this.http.get(environment.BaseUrl + 'General/user_companies/');
  }
  user_branches_per_company(company_id) {
    return this.http.get(environment.BaseUrl + 'General/user_branches_per_company/'+company_id);
  }
  load_approved_products(Branch_Id) {
    return this.http.get(environment.BaseUrl + 'General/load_approved_products/' + Branch_Id);
  }
  load_under_invistigation_products(Branch_Id) {
    return this.http.get(environment.BaseUrl + 'General/load_under_invistigation_products/' + Branch_Id);
  }
  load_rejected_products(Branch_Id) {
    return this.http.get(environment.BaseUrl + 'General/load_rejected_products/' + Branch_Id);
  }
  load_product(product_id,product_id_date){
    return this.http.get(environment.BaseUrl + 'General/load_product/' + product_id+'/'+product_id_date );
  }
  load_product_color_pictures(product_id,product_id_date){
    return this.http.get(environment.BaseUrl + 'General/load_product_color_pictures/' + product_id+'/'+product_id_date );
  }
  delete_product(product_id,product_id_date){
    return this.http.get(environment.BaseUrl + 'General/delete_product/' + product_id+'/'+product_id_date );
  }
  insert_Archived_Products(form_group) {
    return this.http.post(environment.BaseUrl + 'General/insert_Archived_Products', form_group);
  }
  update_Archived_Products(product_id,product_id_date, form_group) {
    return this.http.post(environment.BaseUrl + 'General/update_Archived_Products/' + product_id+'/'+product_id_date, form_group);
  }
  //product color
  insert_Archived_Product_colors(product_id,product_id_date, form_group) {
    return this.http.post(environment.BaseUrl + 'General/insert_Archived_Product_colors/' + product_id+'/'+product_id_date, form_group);
  }
  update_Product_colors(product_id,product_id_date, form_group) {
    return this.http.post(environment.BaseUrl + 'General/update_Product_colors/' + product_id+'/'+product_id_date, form_group);
  }  
  display_product_colors(product_id,product_id_date) {
    return this.http.get(environment.BaseUrl + 'General/display_product_colors/' + product_id+'/'+product_id_date);
  }
  delete_Product_color(product_color_Id :number,product_color_Id_Date  :number) {
    return this.http.get(environment.BaseUrl + 'General/delete_Product_color/' + product_color_Id + '/' + product_color_Id_Date );
  }
  // product pictures
  
  insert_Product_Pictures(form_group, selectedImg : File) {
    const formData : FormData= new FormData();
      formData.append('Image',selectedImg );
      formData.append('ImageData',JSON.stringify(form_group));
    return this.http.post(environment.BaseUrl + 'General/insert_Product_Pictures' , formData);
  }
  display_Product_Pictures(Product_Picture_Id :number,Product_Picture_Id_Date :number) {
  return this.http.get(environment.BaseUrl + 'General/display_Product_Pictures/' + Product_Picture_Id + '/' + Product_Picture_Id_Date );
  }
  update_Product_Pictures(Product_Picture_Id :number,Product_Picture_Id_Date :number, form_group) {
    return this.http.post(environment.BaseUrl + 'General/update_Product_Pictures/' + Product_Picture_Id + '/' + Product_Picture_Id_Date , form_group);
  }
  delete_Product_Pictures(Product_Picture_Id :number,Product_Picture_Id_Date  :number) {
    return this.http.get(environment.BaseUrl + 'General/delete_Product_Pictures/' + Product_Picture_Id + '/' + Product_Picture_Id_Date );
  }
}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-l-general-message',
  templateUrl: './l-general-message.component.html',
  styles: [
  ]
})
export class LGeneralMessageComponent implements OnInit {
  Message : string [] = [
    "",  // 0 general message
  ];
  message_id : number = 0;

  constructor(private currentRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.currentRoute.params.subscribe(
      params => {
        this.load_catigory();
      });
  }
  load_catigory() {
    this.message_id = +this.currentRoute.snapshot.paramMap.get('message_id');
  }

}

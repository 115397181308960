import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../shared/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):  boolean{
      if(localStorage.getItem('Token')!=null)
      {
      let requiredroles = route.data['permitedroles'] as Array<string>  
      if (requiredroles) {
        if  (this.service.roleMatch(requiredroles)){return true;}
        else {localStorage.removeItem('Token');this.router.navigate(['/out/general-message/0']);return false;}
      }
      else{localStorage.removeItem('Token');this.router.navigate(['/out/general-message/0']);return false;}
      return true;
    }
      else{localStorage.removeItem('Token');this.router.navigate(['/out/general-message/0']);return false;}
  }

  constructor(private router : Router, private service : UserService) {

  }
}

import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/shared/general.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styles: [
  ]
})
export class ProductsComponent implements OnInit {
  Company_List = [];
  Branches_List = [];
  FG_Branches = this.FB.group({
    selected_Company_id: [{ value: 0, disabled: false }, [Validators.required, Validators.min(1)]],
    selected_Branch_id: [{ value: 0, disabled: false }, [Validators.required, , Validators.min(1)]],
  })

  Pictures_Url = environment.Pictures_Url;
  products = [];

  selected_product_id: number = 0;
  selected_product_id_date: number = 0;
  constructor(private router : Router, private notificationservice: NotificationService, private service: GeneralService, public FB: UntypedFormBuilder, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.service.user_companies().subscribe(res => {
      this.Company_List = res as any;
    })
  }
  copmany_id_change(company_id_temp) {
    this.service.user_branches_per_company(company_id_temp).subscribe(res => {
      this.Branches_List = res as any;
    })
  }
  Show_Approved_Products() {
    if (this.FG_Branches.valid) {
      this.service.load_approved_products(this.FG_Branches.controls['selected_Branch_id'].value).subscribe(res => {
        this.products = res as any;
      })
        , err => {
          this.notificationservice.send_fail_message("Could not Products"); this.products = [];
        }
    }
  }
  Show_Under_Revision_Products() {    
    if (this.FG_Branches.valid) {
    this.service.load_under_invistigation_products(this.FG_Branches.controls['selected_Branch_id'].value).subscribe(res => {
      this.products = res as any;
    })
      , err => { this.notificationservice.send_fail_message("Could not Products"); this.products = [];
    }
  }
  }
  Show_Rejected_Products() {
    if (this.FG_Branches.valid) {
    this.service.load_rejected_products(this.FG_Branches.controls['selected_Branch_id'].value).subscribe(res => {
      this.products = res as any;
    })
      , err => { this.notificationservice.send_fail_message("Could not Products"); this.products = [];
    }
  }
  }
  view_product(product_Id, product_Id_Date) {     
    this.router.navigate(['/main/view_product/' + product_Id + '/' + product_Id_Date]);
  }
  
  Add_New_Products() {
    if (this.FG_Branches.valid) {
      this.router.navigate(['/main/insert_product/' + this.FG_Branches.controls['selected_Branch_id'].value]);
    }
  }
}

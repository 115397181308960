import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-arabic-link',
  templateUrl: './dialog-arabic-link.component.html',
  styles: [
  ]
})
export class DialogArabicLinkComponent {

}

import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/shared/general.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { InsertCompanyComponent } from '../../Companies/insert-company/insert-company.component';

@Component({
  selector: 'app-insert-branch-arabic',
  templateUrl: './insert-branch-arabic.component.html',
  styles: [
  ]
})
export class InsertBranchArabicComponent implements OnInit {
  company_id = this.data.company_id;
  Cities: any [];
  Areas: any [];

  constructor(private router: Router, private http: HttpClient, public FB: UntypedFormBuilder,@Inject(MAT_DIALOG_DATA) public data, public service: GeneralService,public dialogRef: MatDialogRef<InsertCompanyComponent>, private notificationservice: NotificationService) { }
  FG_Branches = this.FB.group({
    Branch_Name: [{ value: '', disabled: false }, [Validators.required, Validators.maxLength(25)]],
    Branch_Name_Arabic: [{ value: '', disabled: false }, [Validators.required, Validators.maxLength(25)]],
    Branch_Description: ['', [Validators.required, Validators.maxLength(300)]],
    Branch_Description_Arabic: ['', [Validators.required, Validators.maxLength(300)]],
    Branch_Related_Company: [0, Validators.required],
    Branch_Note: ['', [Validators.maxLength(300)]],
    Branch_status: [0, Validators.required],
    Branch_Long: [{ value: 0, disabled: true }, [Validators.required, Validators.pattern("^\-?\\d+\\.\\d+"), Validators.min(-180), Validators.max(180)]],
    Branch_Lat: [{ value: 0, disabled: true }, [Validators.required, Validators.pattern("^\-?\\d+\\.\\d+"), Validators.min(-90), Validators.max(90)]],
    Is_valid_loc: [false, [Validators.requiredTrue]],
    Branch_Address: ['', [Validators.required, Validators.maxLength(80)]],
    Branch_Country: [0, Validators.required],
    Branch_City: [0, [Validators.required, Validators.min(1)]],
    Branch_Area: [0, [Validators.required, Validators.min(1)]],
    Branch_Phone_1: ['', [Validators.required, Validators.maxLength(25)]],
    Branch_Phone_2: ['', [Validators.maxLength(25)]],
    Branch_Phone_3: ['', [Validators.maxLength(25)]],
    Branch_Owner_Phone: ['', [Validators.maxLength(25)]],
    Branch_Is_Main_Branch: [false]
  });
  reset_FG_Branches() {
    this.FG_Branches.patchValue({
      $Branch_ID: 0,
      Branch_Name: '',
      Branch_Description: '',
      Branch_Related_Company: 0,
      Branch_Note: '',
      Branch_Admin_Note: '',
      Branch_Rate: '',
      Branch_status: 0,
      Branch_Long: 0,
      Branch_Lat: 0,
      Is_valid_loc: false,
      Branch_Address: '',
      Branch_Country: 0,
      Branch_City: 0,
      Branch_Area: 0,
      Branch_Updater: 0,
      Branch_Update_Time: '',
      Branch_Description_Arabic: '',
      Branch_Name_Arabic: '',
      Branch_Phone_1: '',
      Branch_Phone_2: '',
      Branch_Phone_3: '',
      Branch_Owner_Phone: '',
      Branch_Ban_Count: 0,
      Branch_Hot_Ban_Count: 0,
      Branch_Ban_Date: '',
      Branch_Ban_Period: 0,
      Branch_Is_Main_Branch: false
    })
  }
  ngOnInit(): void {
    this.initiat_page();
  }
  initiat_page(){
    // this.FG_Branches.controls['Branch_Related_Company'].setValue(this.company_id);    
    this.service.List_Cites(1).subscribe(res => {
      this.Cities = res as [];
    }, err => { this.notificationservice.send_fail_message("Something went wrong") });
    this.reset_FG_Branches();
  }
  cancel(){this.dialogRef.close(false);}
  execute_create(){
    this.Confirm_location(this.FG_Branches.controls['Branch_Lat'].value,this.FG_Branches.controls['Branch_Long'].value)
    if (this.FG_Branches.valid) {
      var body={
        "Branch_Name" : this.FG_Branches.controls['Branch_Name'].value,
        "Branch_Name_Arabic" : this.FG_Branches.controls['Branch_Name_Arabic'].value,
        "Branch_Related_Company" : this.company_id,
        "Branch_Description" :  this.FG_Branches.controls['Branch_Description'].value,
        "Branch_Note" : this.FG_Branches.controls['Branch_Note'].value,
        "Branch_Long" : +this.FG_Branches.controls['Branch_Long'].value,
        "Branch_Lat" : +this.FG_Branches.controls['Branch_Lat'].value,
        "Branch_Address" : this.FG_Branches.controls['Branch_Address'].value,
        "Branch_Area" : +this.FG_Branches.controls['Branch_Area'].value,
        "Branch_Description_Arabic" :this.FG_Branches.controls['Branch_Description_Arabic'].value,
        "Branch_Phone_1" : this.FG_Branches.controls['Branch_Phone_1'].value.toString(),
        "Branch_Phone_2" : this.FG_Branches.controls['Branch_Phone_2'].value.toString(),
        "Branch_Phone_3" : this.FG_Branches.controls['Branch_Phone_3'].value.toString(),
        "Branch_Owner_Phone" : this.FG_Branches.controls['Branch_Owner_Phone'].value.toString(),
        "Branch_Is_Main_Branch" : this.FG_Branches.controls['Branch_Is_Main_Branch'].value
      };
      this.service.Create_Branch(body)
      .subscribe(res=>{this.notificationservice.send_success_message("Branch created");
      this.dialogRef.close(true);},
        err=>{this.notificationservice.send_fail_message("Could not create the branch. Please make sure Branch Name is not repeated.")})
    }else{this.notificationservice.send_fail_message("Branch Could not be created") ;}
  }
  Confirm_location(x,y){if (x==0 || y==0 )
  {this.FG_Branches.controls['Is_valid_loc'].setValue(false);}
  else{this.FG_Branches.controls['Is_valid_loc'].setValue(true);}}
  open_link() { this.service.open_location_link().afterClosed().subscribe(res => { 
    if (res.lat != 0 && res.lng !=0 ) {
      this.FG_Branches.controls['Branch_Lat'].setValue(res.lat);
    this.FG_Branches.controls['Branch_Long'].setValue(res.lng);
  }
  }) }
  change_city() {
    this.service.List_Areas(this.FG_Branches.controls['Branch_City'].value).subscribe(res => {
      this.Areas = res as [];
    }, err => { this.notificationservice.send_fail_message("Something went wrong") });
  }

}

import { Component, Inject, OnInit } from '@angular/core';
// import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-custom-confirm',
  templateUrl: './dialog-custom-confirm.component.html',
  styles: [
  ]
})
export class DialogCustomConfirmComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<DialogCustomConfirmComponent>) { }

  ngOnInit(): void {
  }
  closeDialog(){this.dialogRef.close(false);}
}

import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/shared/general.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { InsertCompanyComponent } from '../../Companies/insert-company/insert-company.component';

@Component({
  selector: 'app-dialog-add-product-colorarabic',
  templateUrl: './dialog-add-product-colorarabic.component.html',
  styles: [
  ]
})
export class DialogAddProductColorarabicComponent implements OnInit {
  product_id = this.data.product_id;
  product_id_date = this.data.product_id_date;

  constructor(private router: Router, private http: HttpClient, public FB: UntypedFormBuilder,@Inject(MAT_DIALOG_DATA) public data, public service: GeneralService,public dialogRef: MatDialogRef<InsertCompanyComponent>, private notificationservice: NotificationService) { }
  formGroup_Product_Color = this.FB.group({
    Product_Color_Related_Archived_Product_Id: [0, [Validators.required , Validators.min(1)]],
    Product_Color_Related_Archived_Product_Id_Date: [0, [Validators.required , Validators.min(1)]],
    Product_Color_Name: ['', [Validators.required, Validators.maxLength(25)]],
    Product_Color_Description: ['', [Validators.required,Validators.maxLength(300)]],
    Product_Color_Order: [0,  [Validators.required , Validators.min(1)] ]
  });
  reset_formGroup_Product_Color() {
    this.formGroup_Product_Color.patchValue({      
    Product_Color_Related_Archived_Product_Id: 0,
    Product_Color_Related_Archived_Product_Id_Date: 0,
    Product_Color_Name: '',
    Product_Color_Description: '',
    Product_Color_Order: 0
    })
  }
  ngOnInit(): void {
    this.initiat_page();
  }
  initiat_page(){
    this.reset_formGroup_Product_Color();
  }
  cancel(){this.dialogRef.close(false);}
  execute_create(formGroup_Product_Color : UntypedFormGroup){
    formGroup_Product_Color.controls['Product_Color_Related_Archived_Product_Id'].setValue(this.product_id)
    formGroup_Product_Color.controls['Product_Color_Related_Archived_Product_Id_Date'].setValue(this.product_id_date)
    formGroup_Product_Color.controls['Product_Color_Order'].setValue(1)
    // const invalid = [];
    // const controls = formGroup_Product_Color.controls;
    // for (const name in controls) {
    //     if (controls[name].invalid) {
    //         invalid.push(name);
    //     }
    // }
    // console.log(invalid)

    if (formGroup_Product_Color.valid) {
      // var body={
      //   "Product_Color_Related_Archived_Product_Id" : this.product_id,
      //   "Product_Color_Related_Archived_Product_Id_Date" : this.product_id_date,
      //   "Product_Color_Name" : formGroup_Product_Color.controls['Product_Color_Name'].value.toString(),
      //   "Product_Color_Description" : formGroup_Product_Color.controls['Product_Color_Description'].value.toString(),
      //   "Product_Color_Order" : formGroup_Product_Color.controls['Product_Color_Order'].value
      // };
      this.service.insert_Archived_Product_colors(this.product_id , this.product_id_date ,formGroup_Product_Color.value)
      .subscribe(res=>{this.notificationservice.send_success_message("Product Color created");
      this.dialogRef.close(true);},
        err=>{this.notificationservice.send_fail_message("Could not create the Product Color. Please make sure Product Color is not repeated.")})
    }else{this.notificationservice.send_fail_message("Product Color could not be created") ;}
  }

}

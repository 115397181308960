import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-master-notlogged',
  templateUrl: './master-notlogged.component.html',
  styles: [
  ]
})
export class MasterNotloggedComponent implements OnInit {

  constructor(private route: Router, @Inject(DOCUMENT) private document: Document) 
  { 
    //this.set_language();
  }
  language_param = 0; //1-Arabic 2-English
  showscroll = false;

  ngOnInit(): void {}
  
  // set_language(){
  //   this.route.events.subscribe((e) => {
  //     if (e instanceof NavigationEnd) {
  //       let link = e.url.toString();
  //       if (link.includes('/ar/')) 
  //       {
  //         this.language_param = 1;
  //       } 
  //       else 
  //       {
  //         this.language_param = 2;
  //       }
  //     }
  //   });
  // }

  lang(e: any) {
    if (e == 2) {
      this.language_param = 2;
      let temp = this.route.url.substring(3)
      this.route.navigate([temp]);
    }
    else if (e == 1) {
      this.language_param = 1;
      let temp = '/ar' + this.route.url
      this.route.navigate([temp]);
    }
  }

}

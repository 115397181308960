import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { GeneralService } from 'src/app/shared/general.service';

@Component({
  selector: 'app-dialog-link',
  templateUrl: './dialog-link.component.html',
  styles: [
  ]
})
export class DialogLinkComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data, public FB: UntypedFormBuilder, public dialogRef: MatDialogRef<DialogLinkComponent>,public service :GeneralService) { }
  FG_Location_link = this.FB.group({
    Link: [{ value: '', disabled: false }, [Validators.required]]  //, Validators.pattern("/^https?\:\/\/(www\.|maps\.)?google\.[a-z]+\/maps\/?\?([^&]+&)*()")
  });
  ngOnInit(): void {
  }
  cancel(){this.dialogRef.close(false);}
  closeDialog(x,y) { var new_owner = { lat: x, lng: y }; this.dialogRef.close(new_owner); }
  return_location(form : UntypedFormGroup){
    if(form.valid){
      var string_1 = form.controls['Link'].value;
      var array_1 = string_1.split("@");
      var array_2 = array_1[1].split(",");
      this.closeDialog(array_2[0],array_2[1]);}   
    }
}

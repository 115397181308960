import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogCustomConfirmComponent } from '../dialog-custom-confirm.component';

@Component({
  selector: 'app-dialog-arabic-custom-confirm',
  templateUrl: './dialog-arabic-custom-confirm.component.html',
  styles: [
  ]
})
export class DialogArabicCustomConfirmComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<DialogArabicCustomConfirmComponent>) { }

  ngOnInit(): void {
  }
  closeDialog(){this.dialogRef.close(false);}
}

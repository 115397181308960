import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nl-general-message',
  templateUrl: './nl-general-message.component.html',
  styles: [
  ]
})
export class NlGeneralMessageComponent implements OnInit {

  constructor(private currentRoute: ActivatedRoute) { }
  Message : string [] = [
    "",  // 0 general message not used
  ];
  message_id : number = 0;

  ngOnInit(): void {
    this.message_id = +this.currentRoute.snapshot.paramMap.get('message_id');
      }
  
}

import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
// import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/shared/general.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { InsertCompanyComponent } from '../../Companies/insert-company/insert-company.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-update-product-color',
  templateUrl: './dialog-update-product-color.component.html',
  styles: [
  ]
})
export class DialogUpdateProductColorComponent implements OnInit {
  product_id = this.data.product_color_id;
  product_id_date = this.data.product_color_id_date;
  constructor(private router: Router, private http: HttpClient, public FB: UntypedFormBuilder,@Inject(MAT_DIALOG_DATA) public data, public service: GeneralService,public dialogRef: MatDialogRef<InsertCompanyComponent>, private notificationservice: NotificationService) { }
  formGroup_Product_Color = this.FB.group({
    Product_Color_Id: [0, [Validators.required , Validators.min(1)]],
    Product_Color_Id_Date: [0, [Validators.required , Validators.min(1)]],
    Product_Color_Related_Archived_Product_Id: [0, [Validators.required , Validators.min(1)]],
    Product_Color_Related_Archived_Product_Id_Date: [0, [Validators.required , Validators.min(1)]],
    Product_Color_Name: ['', [Validators.required, Validators.maxLength(25)]],
    Product_Color_Description: ['', [Validators.maxLength(300)]],
    Product_Color_Order: [0,  [Validators.required , Validators.min(1)] ]
  });
  reset_formGroup_Product_Color() {
    this.formGroup_Product_Color.patchValue({      
    Product_Color_Related_Archived_Product_Id: 0,
    Product_Color_Related_Archived_Product_Id_Date: 0,
    Product_Color_Name: '',
    Product_Color_Description: '',
    Product_Color_Order: 0
    })
  }
  ngOnInit(): void {
    this.initiat_page();
    this.refresh();
  }
  initiat_page(){
    this.reset_formGroup_Product_Color();
  }
  cancel(){this.dialogRef.close(false);}
  refresh(){   
    this.service.display_product_colors(this.product_id,this.product_id_date).subscribe((res: any) => {
       this.formGroup_Product_Color.patchValue({
        Product_Color_Id: res.product_Color_Id,
        Product_Color_Id_Date: res.product_Color_Id_Date,
        Product_Color_Related_Archived_Product_Id: res.product_Color_Related_Archived_Product_Id,
        Product_Color_Related_Archived_Product_Id_Date: res.product_Color_Related_Archived_Product_Id_Date,
        Product_Color_Name: res.product_Color_Name,
        Product_Color_Description: res.product_Color_Description,
        Product_Color_Order:res.product_Color_Order
       });
     }),err=>{this.notificationservice.send_fail_message("Product Color load Failed");}
  }
  update_product_color(formGroup_Create_Company){
    // console.log(formGroup_Create_Company.getRawValue())
  this.service.update_Product_colors(this.product_id,this.product_id_date,formGroup_Create_Company.getRawValue())
  .subscribe(res=>{
    this.refresh();this.notificationservice.send_success_message("Product Color Updated");this.dialogRef.close(true);}
    ,err=>{this.refresh(); this.notificationservice.send_fail_message("Product Color Update Failed");})
}
delete_product(Product_Color_Id:number,Product_Color_Id_Date:number){
  this.service.openConfirmDialog("Are you sure you want to delete Product Color?")
  .afterClosed().subscribe(res=>{
      if (res) {
        this.service.delete_Product_color(Product_Color_Id,Product_Color_Id_Date).subscribe(
      res=>{this.notificationservice.send_success_message("Product Color Deleted"); 
      this.dialogRef.close(true);},
      err=>{this.notificationservice.send_fail_message("Could not Delete the Product Color."); })
      }
        
      else { this.notificationservice.send_fail_message("Your Product Color is not Deleted"); }
    }
    , err => { this.notificationservice.send_fail_message("Could not Delete Your Product Color");})
}
}

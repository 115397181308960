import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { MatInputModule } from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCheckboxModule } from '@angular/material/checkbox';
// import { MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input'
// import { MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import { CommonModule, DatePipe } from '@angular/common';
// import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import { MatDialogModule } from '@angular/material/dialog';
// import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
// import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
// import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';

// import { FlexModule } from '@angular/flex-layout';
// import { FlexLayoutModule } from "@angular/flex-layout";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopBarComponent } from './common/top-bar/top-bar.component';
import { HeaderComponent } from './common/header/header.component';
import { MasterLoggedComponent } from './General/master-logged/master-logged.component';
import { MasterNotloggedComponent } from './General/master-notlogged/master-notlogged.component';
import { ArabicHeaderComponent } from './common/header/arabic/arabic-header/arabic-header.component';
import { ArabicTopBarComponent } from './common/top-bar/arabic/arabic-top-bar/arabic-top-bar.component';
import { BranchesComponent } from './Pages/Branches/branches/branches.component';
import { PointsComponent } from './Pages/Points/points/points.component';
import { ProductsComponent } from './Pages/Products/products/products.component';
import { ArabicproductsComponent } from './Pages/Products/arabicproducts/arabicproducts.component';
import { ArabicbranchesComponent } from './Pages/Branches/arabicbranches/arabicbranches.component';
import { ArabicpointsComponent } from './Pages/Points/arabicpoints/arabicpoints.component';
import { HomeComponent } from './Pages/General/Home/home/home.component';
import { ArabichomeComponent } from './Pages/General/Home/arabichome/arabichome.component';
import { SignInComponent } from './Pages/General/sign-in/sign-in/sign-in.component';
import { ArabicSignInComponent } from './Pages/General/sign-in/arabic-sign-in/arabic-sign-in.component';
import { LGeneralMessageComponent } from './Pages/General/logged-general-message/l-general-message/l-general-message.component';
import { ArlGeneralMessageComponent } from './Pages/General/logged-general-message/arl-general-message/arl-general-message.component';
import { ArnlGeneralMessageComponent } from './Pages/General/not-logged-general-message/arnl-general-message/arnl-general-message.component';
import { NlGeneralMessageComponent } from './Pages/General/not-logged-general-message/nl-general-message/nl-general-message.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth.interceptor';
import { NotificationService } from './shared/notification.service';
import { UserService } from './shared/user.service';
import { CookieService } from 'ngx-cookie-service';
import { AddOnlineTimeslotComponent } from './Dialog/add-online-timeslot/add-online-timeslot.component';
import { AddFrontpageTimeslotComponent } from './Dialog/add-frontpage-timeslot/add-frontpage-timeslot.component';
import { ExtendOnlineTsComponent } from './Dialog/extend-online-ts/extend-online-ts.component';
import { ExtendFrontpageTsComponent } from './Dialog/extend-frontpage-ts/extend-frontpage-ts.component';
import { InsertBranchComponent } from './Pages/Branches/insert-branch/insert-branch.component';
import { InsertBranchArabicComponent } from './Pages/Branches/insert-branch-arabic/insert-branch-arabic.component';
import { UpdateBranchArabicComponent } from './Pages/Branches/update-branch-arabic/update-branch-arabic.component';
import { UpdateBranchComponent } from './Pages/Branches/update-branch/update-branch.component';
import { ViewBranchComponent } from './Pages/Branches/view-branch/view-branch.component';
import { ViewBranchArabicComponent } from './Pages/Branches/view-branch-arabic/view-branch-arabic.component';
import { ViewCompanyArabicComponent } from './Pages/Companies/view-company-arabic/view-company-arabic.component';
import { ViewCompanyComponent } from './Pages/Companies/view-company/view-company.component';
import { InsertCompanyComponent } from './Pages/Companies/insert-company/insert-company.component';
import { InsertCompanyArabicComponent } from './Pages/Companies/insert-company-arabic/insert-company-arabic.component';
import { UpdateCompanyArabicComponent } from './Pages/Companies/update-company-arabic/update-company-arabic.component';
import { UpdateCompanyComponent } from './Pages/Companies/update-company/update-company.component';
import { DialogManualComponent } from './Dialog/locations/dialog-manual/dialog-manual.component';
import { DialogLinkComponent } from './Dialog/locations/dialog-link/dialog-link.component';
import { DialogMapComponent } from './Dialog/locations/dialog-map/dialog-map.component';
import { DialogCustomConfirmComponent } from './Dialog/dialog-custom-confirm/dialog-custom-confirm.component';
import { AddCompanyPhotoComponent } from './Pages/Companies/add-company-photo/add-company-photo.component';
import { ViewCompanyPhotosComponent } from './Pages/Companies/view-company-photos/view-company-photos.component';
import { InsertProductComponent } from './Pages/Products/insert-product/insert-product.component';
import { UpdateProductComponent } from './Pages/Products/update-product/update-product.component';
import { ArabicUpdateProductComponent } from './Pages/Products/arabic-update-product/arabic-update-product.component';
import { ArabicInsertProductComponent } from './Pages/Products/arabic-insert-product/arabic-insert-product.component';
import { ArabicViewProductComponent } from './Pages/Products/arabic-view-product/arabic-view-product.component';
import { ViewProductComponent } from './Pages/Products/view-product/view-product.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { GeneralService } from './shared/general.service';
import { DialogAddProductColorComponent } from './Pages/product_colors/dialog-add-product-color/dialog-add-product-color.component';
import { DialogAddProductColorarabicComponent } from './Pages/product_colors/dialog-add-product-colorarabic/dialog-add-product-colorarabic.component';
import { DialogUpdateProductColorarabicComponent } from './Pages/product_colors/dialog-update-product-colorarabic/dialog-update-product-colorarabic.component';
import { DialogUpdateProductColorComponent } from './Pages/product_colors/dialog-update-product-color/dialog-update-product-color.component';
// import { DialogAddEditProductPicturesComponent } from './Pages/products/dialog-add-edit-product-pictures/dialog-add-edit-product-pictures.component';
import { DialogAddEditProductPicturesComponent } from './Pages/Products/dialog-add-edit-product-pictures/dialog-add-edit-product-pictures.component';
import {MatIconModule} from '@angular/material/icon';
import { NgxCaptchaModule } from 'ngx-captcha';
import { DialogArabicCustomConfirmComponent } from './Dialog/dialog-custom-confirm/dialog-arabic-custom-confirm/dialog-arabic-custom-confirm.component';
import { AddArabicOnlineTimeslotComponent } from './Dialog/add-online-timeslot/add-arabic-online-timeslot/add-arabic-online-timeslot.component';
import { AddArabicFrontpageTimeslotComponent } from './Dialog/add-frontpage-timeslot/add-arabic-frontpage-timeslot/add-arabic-frontpage-timeslot.component';
import { ExtendArabicFrontpageTsComponent } from './Dialog/extend-frontpage-ts/extend-arabic-frontpage-ts/extend-arabic-frontpage-ts.component';
import { ExtendArabicOnlineTsComponent } from './Dialog/extend-online-ts/extend-arabic-online-ts/extend-arabic-online-ts.component';
import { DialogArabicLinkComponent } from './Dialog/locations/dialog-link/dialog-arabic-link/dialog-arabic-link.component';
import { DialogArabicManualComponent } from './Dialog/locations/dialog-manual/dialog-arabic-manual/dialog-arabic-manual.component';
import { DialogArabicMapComponent } from './Dialog/locations/dialog-map/dialog-arabic-map/dialog-arabic-map.component';
import { AddCompanyArabicPhotoComponent } from './Pages/Companies/add-company-photo/add-company-arabic-photo/add-company-arabic-photo.component';
import { ViewCompanyPhotosArabicComponent } from './Pages/Companies/view-company-photos/view-company-photos-arabic/view-company-photos-arabic.component';
import { DialogAddEditProductPicturesArabicComponent } from './Pages/Products/dialog-add-edit-product-pictures/dialog-add-edit-product-pictures-arabic/dialog-add-edit-product-pictures-arabic.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    HeaderComponent,
    MasterLoggedComponent,
    MasterNotloggedComponent,
    ArabicHeaderComponent,
    ArabicTopBarComponent,
    BranchesComponent,
    PointsComponent,
    ProductsComponent,
    ArabicproductsComponent,
    ArabicbranchesComponent,
    ArabicpointsComponent,
    HomeComponent,
    ArabichomeComponent,
    SignInComponent,
    ArabicSignInComponent,
    LGeneralMessageComponent,
    ArlGeneralMessageComponent,
    ArnlGeneralMessageComponent,
    NlGeneralMessageComponent,
    AddOnlineTimeslotComponent,
    AddFrontpageTimeslotComponent,
    ExtendOnlineTsComponent,
    ExtendFrontpageTsComponent,
    InsertBranchComponent,
    InsertBranchArabicComponent,
    UpdateBranchArabicComponent,
    UpdateBranchComponent,
    ViewBranchComponent,
    ViewBranchArabicComponent,
    ViewCompanyArabicComponent,
    ViewCompanyComponent,
    InsertCompanyComponent,
    InsertCompanyArabicComponent,
    UpdateCompanyArabicComponent,
    UpdateCompanyComponent,
    DialogManualComponent,
    DialogLinkComponent,
    DialogMapComponent,
    DialogCustomConfirmComponent,
    AddCompanyPhotoComponent,
    ViewCompanyPhotosComponent,
    InsertProductComponent,
    UpdateProductComponent,
    ArabicUpdateProductComponent,
    ArabicInsertProductComponent,
    ArabicViewProductComponent,
    ViewProductComponent,
    DialogAddProductColorComponent,
    DialogAddProductColorarabicComponent,
    DialogUpdateProductColorarabicComponent,
    DialogUpdateProductColorComponent,
    DialogAddEditProductPicturesComponent,
    DialogArabicCustomConfirmComponent,
    AddArabicOnlineTimeslotComponent,
    AddArabicFrontpageTimeslotComponent,
    ExtendArabicFrontpageTsComponent,
    ExtendArabicOnlineTsComponent,
    DialogArabicLinkComponent,
    DialogArabicManualComponent,
    DialogArabicMapComponent,
    AddCompanyArabicPhotoComponent,
    ViewCompanyPhotosArabicComponent,
    DialogAddEditProductPicturesArabicComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    CarouselModule,
    MatIconModule,
    MatInputModule, MatSnackBarModule,MatSelectModule,
    MatDialogModule,
    MatFormFieldModule,MatCheckboxModule,MatRadioModule,
    HttpClientModule,
    NgxCaptchaModule
  ],
  providers: [
    // GeneralService,
    NotificationService,UserService,CookieService,DatePipe,
    {provide: HTTP_INTERCEPTORS,
    useClass:AuthInterceptor,
    multi:true},    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

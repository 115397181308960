import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/shared/general.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { UserService } from 'src/app/shared/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: [
  ]
})
export class HomeComponent implements OnInit {

  constructor(public service: GeneralService,public userservice:UserService,public router:Router, private notificationservice: NotificationService) { }
  Pictures_Url = environment.Pictures_Url;
  Logo_URL = "brand10_resized.webp";
  companies : any = [] ;
  ngOnInit(): void {
    this.check_language()
    this.load_companies()
  }
  load_companies() {
    this.service.user_home().subscribe(res => {
      this.companies = res;
    }, 
    err => { this.notificationservice.send_fail_message("Failed load to Your Companies")})
  }
  
  check_language(){
    if(this.userservice.getCookie('lang') == 'ar'){
      this.router.navigateByUrl('/ar/main/home')
    }
  }
  //company management
  dialog_create_company(){
    this.service.dialog_create_company()
    .afterClosed().subscribe(res=>{
        if (res) {           
           this.load_companies();}
        else {  this.load_companies();}
      }
      , err => {this.load_companies();})
  }  
  dialog_update_company(company_id:number){
    this.service.dialog_update_company(company_id)
    .afterClosed().subscribe(res=>{
        if (res) {           
          this.notificationservice.send_success_message("Your Company is Updated"); this.load_companies();}
        else { this.notificationservice.send_fail_message("Your Company is not Updated"); this.load_companies();}
      }
      , err => { this.notificationservice.send_fail_message("Could not update Your Company"); this.load_companies();})
  }
  dialog_view_company(company_id:number){
    this.service.dialog_view_company(company_id)
    .afterClosed().subscribe(res=>{
        if (res) {           
           this.load_companies();}
        else { this.load_companies();}
      }
      , err => { this.load_companies();})
  }
  dialog_add_company_photo(company_id:number){
    this.service.dialog_add_company_photo(company_id)
    .afterClosed().subscribe(res=>{
        if (res) {           
           this.load_companies();}
        else { }
      }
      , err => { })
  }
  dialog_view_company_photos(company_id:number){
    this.service.dialog_view_company_photos(company_id)
    .afterClosed().subscribe(res=>{
      }
      , err => { })
  }
  dialog_delete_company(company_id:number){
    this.service.openConfirmDialog("Are you sure you want to delete the Company?")
    .afterClosed().subscribe(res=>{
        if (res) {   
          
          this.service.delete_company(company_id).subscribe(
        res=>{this.notificationservice.send_success_message("Company Deleted"); this.load_companies();},
        err=>{this.notificationservice.send_fail_message("Could not Delete the Company."); this.load_companies();})
        }
          
        else { this.notificationservice.send_fail_message("Your Company is not Deleted"); this.load_companies();}
      }
      , err => { this.notificationservice.send_fail_message("Could not Delete Your Company"); this.load_companies();})
  }
  //branch management
  dialog_create_branch(company_Id){
    this.service.dialog_create_branch(company_Id)
    .afterClosed().subscribe(res=>{
        if (res) {           
          // this.notificationservice.send_success_message("Your Branch is added");
           this.load_companies();
          }
        else { 
         this.load_companies();
        }
      }
      , err => { this.notificationservice.send_fail_message("Could not add Your Branch"); this.load_companies();})
  }  
  dialog_update_branch(branch_id:number){
    this.service.dialog_update_branch(branch_id)
    .afterClosed().subscribe(res=>{
        if (res) {           
          this.load_companies();}
        else {  this.load_companies();}
      }
      , err => { this.notificationservice.send_fail_message("Could not update Your Branch"); this.load_companies();})
  }
  dialog_view_branch(branch_id:number){
    this.service.dialog_view_branch(branch_id)
    .afterClosed().subscribe(res=>{
        if (res) {           
           this.load_companies();}
        else { this.load_companies();}
      }
      , err => { this.load_companies();})
  }
  dialog_delete_branch(branch_id:number){
    this.service.openConfirmDialog("Are you sure you want to delete this Branch?")
    .afterClosed().subscribe(res=>{
        if (res) {   
          this.service.delete_branch(branch_id).subscribe(
        res=>{this.notificationservice.send_success_message("Branch Deleted"); this.load_companies();},
        err=>{this.notificationservice.send_fail_message("Could not Delete the Branch."); this.load_companies();})
        }          
        else { this.notificationservice.send_fail_message("Your Branch is not Deleted"); this.load_companies();}
      }
      , err => { this.notificationservice.send_fail_message("Could not Delete Your Branch"); this.load_companies();})
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { GeneralService } from 'src/app/shared/general.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-product',
  templateUrl: './view-product.component.html',
  styles: [
  ]
})
export class ViewProductComponent implements OnInit {

  constructor(private currentRoute: ActivatedRoute, private service: GeneralService) { }
  Pictures_Url = environment.Pictures_Url
  product_id = 0;
  product_id_date = 0;

  product_name = ""
  product_status = ""
  product_description = ""
  product_name_arabic = ""
  product_description_arabic = ""
  product_note = ""
    
  picPath = ""
  If_has_pic = false
  
  product_Company_Id = 0;
  product_Branch_Id = 0;

  product_colors: any = [];
  product_pictures: any = [];
  ngOnInit(): void {
    this.getCurrentProduct()
  }
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 300,
    margin: 20,
    autoHeight : true,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 2
      },
      400: {
        items: 3
      },
      500: {
        items: 3
      },
      740: {
        items: 3
      },
      940: {
        items: 5
      }
    },
    nav: true
  }
  changeImg(img: string) {
    this.If_has_pic = true;
    this.picPath = img;
  }
  getCurrentProduct() {
    this.product_id = +this.currentRoute.snapshot.paramMap.get('id');
    this.product_id_date = +this.currentRoute.snapshot.paramMap.get('id_date');
    if (this.product_id && this.product_id_date) {
      this.service.load_product(this.product_id, this.product_id_date).subscribe((res: any) => {
        this.product_name = res.product_Data.product_Name
        this.product_description = res.product_Data.product_Description
        this.product_name_arabic = res.product_Data.product_Name_Arabic
        this.product_description_arabic = res.product_Data.product_Description_Arabic
        this.product_status =res.product_Data.products_Status_name
        this.product_note = res.product_Data.product_Note
        if(res.product_Data.product_Pic_path != null)
        {this.If_has_pic = true 
          this.picPath = res.product_Data.product_Pic_path
        }
        else{this.If_has_pic = false}
        // this.product_Company_Name = res.product_Data.product_Company_Name
        this.product_Company_Id = res.product_Data.product_Company_Id
        this.product_colors = res.product_Colors_Summary
        this.product_pictures = res.product_Pictures_Summary
        this.product_Company_Id = res.product_Data.product_Company_Id 
        this.product_Branch_Id = res.product_Data.product_Related_Branch 
      })
    }
  }
  changeColor(product_Color_Id: number,product_Color_Id_Date:number) {
    if (product_Color_Id && product_Color_Id_Date) {
      this.service.load_product_color_pictures(product_Color_Id, product_Color_Id_Date).subscribe((res: any) => {
        this.product_pictures = res
      })
  }}
  
}

import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NotificationService } from 'src/app/shared/notification.service';
import { UserService } from 'src/app/shared/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styles: [
  ]
})
export class SignInComponent implements OnInit {

  constructor( public service : UserService , private cookieservice: CookieService, private notification : NotificationService,private router : Router ) { }
  //
  public readonly siteKey: string = environment.recaptcha.siteKey;
  ngOnInit(): void {
    if (localStorage.getItem('Token')!=null){
      this.router.navigateByUrl('/main/home');
    }
    else{
    }    
  }
  signin( ) {
    // console.log(this.service.Login_FG.valid)
    // console.log(this.service.Login_FG.value)
    if (this.service.Login_FG.valid) {
      this.service.Login(this.service.Login_FG.value).subscribe(
        (res:any)=>{
          localStorage.setItem('Token',res.token);
          localStorage.setItem('First_Name', res.user_First_Name);
          // this.cookieservice.set("Test","Test1")
          // this.cookieservice.get("Test")
      this.service.reset_Login_FG();
        this.router.navigateByUrl('/main/home');},
        err=>{
          if(err.status == 400)
          this.notification.send_fail_message('Incorrect Username or Password')
          else
          this.notification.send_fail_message('Unknown error')
        }
      );
    }
  }
}

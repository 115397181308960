import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { MasterLoggedComponent } from './General/master-logged/master-logged.component';
import { MasterNotloggedComponent } from './General/master-notlogged/master-notlogged.component';
import { ArabicbranchesComponent } from './Pages/Branches/arabicbranches/arabicbranches.component';
import { BranchesComponent } from './Pages/Branches/branches/branches.component';
import { ArabichomeComponent } from './Pages/General/Home/arabichome/arabichome.component';
import { HomeComponent } from './Pages/General/Home/home/home.component';
import { ArlGeneralMessageComponent } from './Pages/General/logged-general-message/arl-general-message/arl-general-message.component';
import { LGeneralMessageComponent } from './Pages/General/logged-general-message/l-general-message/l-general-message.component';
import { ArnlGeneralMessageComponent } from './Pages/General/not-logged-general-message/arnl-general-message/arnl-general-message.component';
import { NlGeneralMessageComponent } from './Pages/General/not-logged-general-message/nl-general-message/nl-general-message.component';
import { ArabicSignInComponent } from './Pages/General/sign-in/arabic-sign-in/arabic-sign-in.component';
import { SignInComponent } from './Pages/General/sign-in/sign-in/sign-in.component';
import { ArabicpointsComponent } from './Pages/Points/arabicpoints/arabicpoints.component';
import { PointsComponent } from './Pages/Points/points/points.component';
import { ArabicViewProductComponent } from './Pages/Products/arabic-view-product/arabic-view-product.component';
import { ArabicproductsComponent } from './Pages/Products/arabicproducts/arabicproducts.component';
import { InsertProductComponent } from './Pages/Products/insert-product/insert-product.component';
import { ProductsComponent } from './Pages/Products/products/products.component';
import { UpdateProductComponent } from './Pages/Products/update-product/update-product.component';
import { ViewProductComponent } from './Pages/Products/view-product/view-product.component';
import { ArabicInsertProductComponent } from './Pages/Products/arabic-insert-product/arabic-insert-product.component';
import { ArabicUpdateProductComponent } from './Pages/Products/arabic-update-product/arabic-update-product.component';

const routes: Routes = [
  { path: '', redirectTo: 'main/home', pathMatch: 'full' },
  { path: 'main', component: MasterLoggedComponent, canActivate: [AuthGuard],data:{permitedroles:['Customer_Normal'] }, children: [
      { path: 'home', component: HomeComponent},
      { path: 'points_management', component: PointsComponent},
      { path: 'products_management', component: ProductsComponent },
      { path: 'my_branches', component: BranchesComponent},
      { path: 'general-message/:message_id', component: LGeneralMessageComponent },
      { path: 'insert_product/:branch_id', component: InsertProductComponent },
      { path: 'update_product/:id/:id_date', component: UpdateProductComponent },
      { path: 'view_product/:id/:id_date', component: ViewProductComponent },

    ]
  },
  { path: 'out', component: MasterNotloggedComponent, children: [
    { path: 'sign-in', component: SignInComponent },
    { path: 'general-message/:message_id', component: NlGeneralMessageComponent },
    
  ]
  },


  { path: 'ar/main', component: MasterLoggedComponent, canActivate: [AuthGuard],data:{permitedroles:['Customer_Normal'] }, children: [
    
    { path: 'home', component: ArabichomeComponent},
    { path: 'points_management', component: ArabicpointsComponent},
    { path: 'products_management', component: ArabicproductsComponent },
    { path: 'my_branches', component: ArabicbranchesComponent},
    { path: 'general-message/:message_id', component: ArlGeneralMessageComponent },
    { path: 'insert_product/:branch_id', component: ArabicInsertProductComponent },
    { path: 'update_product/:id/:id_date', component: ArabicUpdateProductComponent },
    { path: 'view_product/:id/:id_date', component: ArabicViewProductComponent },
  ]
},
{ path: 'ar/out', component: MasterNotloggedComponent, children: [  
  { path: 'sign-in', component: ArabicSignInComponent }, //ok
  { path: 'general-message/:message_id', component: ArnlGeneralMessageComponent },
]
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styles: [
  ]
})
export class TopBarComponent implements OnInit {

  constructor(private route:Router,public service : UserService) { }
  username = localStorage.getItem('First_Name');
  Pictures_Url = environment.Pictures_Url;

  ngOnInit(): void {
  }

  lang(e:any){
    if(e == 1){
        this.service.setCookie({
          name: 'lang',
          value: 'ar',
          session: true,
          expireDays:1000
        });
        let temp = '/ar' + this.route.url
        this.route.navigate([temp]);
    }
    else if (e == 2){
      this.service.setCookie({
        name: 'lang',
        value: 'en',
        session: true,
        expireDays:1000
      });
      let temp = this.route.url.substring(3)
      this.route.navigate([temp]);
    }
  }
  onLogout(){
    localStorage.removeItem('Token');
    this.route.navigateByUrl('/out/sign-in');
  }
}

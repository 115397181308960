import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GeneralService } from 'src/app/shared/general.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { environment } from 'src/environments/environment';
import { DialogAddEditProductPicturesComponent } from '../dialog-add-edit-product-pictures.component';

@Component({
  selector: 'app-dialog-add-edit-product-pictures-arabic',
  templateUrl: './dialog-add-edit-product-pictures-arabic.component.html',
  styles: [
  ]
})
export class DialogAddEditProductPicturesArabicComponent {
  imgSrc: string = '/assets/images/Product_Pictures/0/0/Product_Initial_Pic.jpg';
  selectedImg: any = null;
  constructor(@Inject(MAT_DIALOG_DATA) public data, public FB: UntypedFormBuilder,public notificationservice:NotificationService, public service: GeneralService,public dialogRef: MatDialogRef<DialogAddEditProductPicturesComponent>) { }
  formGroup_Product_Pictures = this.FB.group({
    Product_Picture_Id: [{value:0, disabled: true }],
    Product_Picture_Id_Date: [{value:0, disabled: true }],
    Product_Picture_Related_Picture_Color_Id: [{value:0, disabled: true },[Validators.required,Validators.min(1)]],
    Product_Picture_Related_Picture_Color_Id_Date: [{value:0, disabled: true },[Validators.required,Validators.min(1)] ],
    Product_Picture_Store_Name: [{value:'', disabled: true }, [Validators.required, Validators.maxLength(50)]],
    Product_Picture_Extention: [{value:'', disabled: true }, [Validators.required, Validators.maxLength(6)]],
    Product_Picture_Order: [1, [Validators.required,Validators.min(1)] ],
    Product_Picture_Comment: ['', [Validators.maxLength(25)]],
    Product_Picture_Is_Deleted: false,
    Product_Picture_Is_approved:false,
    Product_Picture_Updater: [{value:0, disabled: true }],
    Product_Picture_Updater_username: [{value:'', disabled: true }],
    Product_Picture_Update_Time: [{value:'', disabled: true }],
    Dummy_Has_Photo : [false, [Validators.requiredTrue]],
    });
    reset_Product_Pictures(){
      this.formGroup_Product_Pictures.patchValue({
        Product_Picture_Id: 0,
        Product_Picture_Id_Date: 0,
        Product_Picture_Related_Picture_Color_Id: 0,
        Product_Picture_Related_Picture_Color_Id_Date: 0,
        Product_Picture_Store_Name: '',
        Product_Picture_Extention: '',
        Product_Picture_Order: 1,
        Product_Picture_Comment: '',
        Product_Picture_Is_Deleted: false,
        Product_Picture_Is_approved:false,
        Product_Picture_Updater: 0,
        Product_Picture_Updater_username: '',
        Product_Picture_Update_Time: '',
        Dummy_Has_Photo : false
      })
    }
  ngOnInit(): void {
    if(!this.data.If_Update_Picture){this.reset_Product_Pictures();
      this.formGroup_Product_Pictures.controls["Product_Picture_Related_Picture_Color_Id"].setValue( +this.data.product_Color_Id);
      this.formGroup_Product_Pictures.controls["Product_Picture_Related_Picture_Color_Id_Date"].setValue(+this.data.product_picture_id_date);}
    else{this.service.display_Product_Pictures(this.data.product_picture_id,this.data.product_picture_id_date).subscribe((res:any)=>{      
      this.imgSrc = environment.Pictures_Url + 'Product_Pictures/' + res.product_Related_Company + '/' + res.product_Related_Branch + '/' + res.product_Picture_Store_Name + "." + res.product_Picture_Extention;
      this.formGroup_Product_Pictures.controls['Dummy_Has_Photo'].setValue(true);
      this.formGroup_Product_Pictures.patchValue({
        Product_Picture_Id: +res.product_Picture_Id,
        Product_Picture_Id_Date: +res.product_Picture_Id_Date,
        Product_Picture_Related_Picture_Color_Id: +res.product_Picture_Related_Picture_Color_Id,
        Product_Picture_Related_Picture_Color_Id_Date: +res.product_Picture_Related_Picture_Color_Id_Date,
        Product_Picture_Store_Name: res.product_Picture_Store_Name,
        Product_Picture_Extention: res.product_Picture_Extention,
        Product_Picture_Order: +res.product_Picture_Order,
        Product_Picture_Comment: res.product_Picture_Comment,
        Product_Picture_Is_Deleted: res.product_Picture_Is_Deleted,
        Product_Picture_Is_approved:res.Product_Picture_Is_approved,
        Product_Picture_Updater: +res.product_Picture_Updater,
        Product_Picture_Updater_username: +res.updater_username,
        Product_Picture_Update_Time: res.product_Picture_Update_Time,
        });        
    },err=>{this.notificationservice.send_fail_message("Could not load the Product Picture.")})}
  }
  cancel(){this.dialogRef.close(false);}
  edit(x : UntypedFormGroup){
    var body ={      
      "Product_Picture_Id": +x.controls["Product_Picture_Id"].value,
      "Product_Picture_Id_Date": +x.controls["Product_Picture_Id_Date"].value,
      "Product_Picture_Order": +x.controls["Product_Picture_Order"].value,
      "Product_Picture_Comment": x.controls["Product_Picture_Comment"].value,
      "Product_Picture_Is_Deleted": x.controls["Product_Picture_Is_Deleted"].value
      }
    this.service.update_Product_Pictures(this.data.product_picture_id,this.data.product_picture_id_date,body).subscribe(
      res=>{this.dialogRef.close(true);}
    ,err=>{this.dialogRef.close(false);})}
  add(x : UntypedFormGroup){
    if(x.valid){      
    var body ={
      "Product_Picture_Related_Picture_Color_Id": +this.data.product_picture_id, // it is product color id not picture id
      "Product_Picture_Related_Picture_Color_Id_Date": +this.data.product_picture_id_date, // it is product color id not picture id date
      "Product_Picture_Order": +x.controls["Product_Picture_Order"].value,
      "Product_Picture_Store_Name": x.controls["Product_Picture_Store_Name"].value,
      "Product_Picture_Extention": x.controls["Product_Picture_Extention"].value
    };
    this.service.insert_Product_Pictures(body,this.selectedImg).subscribe(
      res=>{this.dialogRef.close(true);this.notificationservice.send_success_message("Product Picture Added");}
      ,err=>{this.dialogRef.close(false);this.notificationservice.send_fail_message("Product Picture Could not be added");})
    }else{this.notificationservice.send_fail_message("Product Picture information is not complete");}
  }
showPreview(event: any) {
  if (event.target.files.length > 0) {
    const reader = new FileReader();
    reader.onload = (e: any) =>
      this.imgSrc = e.target.result;
    reader.readAsDataURL(event.target.files[0])
    this.selectedImg = event.target.files[0];
    this.formGroup_Product_Pictures.controls['Dummy_Has_Photo'].setValue(true);
  var name = this.selectedImg.name.split('.')[0];
  var ext = this.selectedImg.name.split('.')[1];
    this.formGroup_Product_Pictures.controls['Product_Picture_Store_Name'].setValue(name);
    this.formGroup_Product_Pictures.controls['Product_Picture_Extention'].setValue(ext);
  } else {
    this.imgSrc = '/assets/images/Product_Pictures/0/0/Product_Initial_Pic.jpg';
    this.selectedImg = null;
    this.formGroup_Product_Pictures.controls['Dummy_Has_Photo'].setValue(false);
  }
}
delete_product(Product_Picture_Id:number,Product_Picture_Id_Date:number){
  this.service.openConfirmDialog("Are you sure you want to delete this Picture?")
  .afterClosed().subscribe(res=>{
      if (res) {
        this.service.delete_Product_Pictures(Product_Picture_Id,Product_Picture_Id_Date).subscribe(
      res=>{this.notificationservice.send_success_message("Picture Deleted"); 
      this.dialogRef.close(true);},
      err=>{this.notificationservice.send_fail_message("Could not Delete the Picture."); })
      }
        
      else { this.notificationservice.send_fail_message("Your Picture is not Deleted"); }
    }
    , err => { this.notificationservice.send_fail_message("Could not Delete Your Product Picture");})
}
}

import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/app/shared/general.service';
import { NotificationService } from 'src/app/shared/notification.service';

@Component({
  selector: 'app-insert-product',
  templateUrl: './insert-product.component.html',
  styles: [
  ]
})
export class InsertProductComponent implements OnInit {
  Cat_1_list : any =[];Cat_2_list : any =[];Cat_3_list: any =[];Cat_4_list : any =[];
  cat_1_selected = 0;cat_2_selected = 0;cat_3_selected = 0;cat_4_selected = 0;
  prod_delivery_statuses : any =[];
  constructor(public service: GeneralService, public FB: UntypedFormBuilder,  private currentRoute: ActivatedRoute, private datePipe: DatePipe, public notificationService: NotificationService,  private router : Router) { }
  formGroup_insert_Archived_Products = this.FB.group({
    Archived_Product_Name: ['', [Validators.required, Validators.maxLength(50)]],
    Archived_Product_Name_Arabic: ['', [Validators.required, Validators.maxLength(50)]],
    Archived_Product_Description: ['', [Validators.required, Validators.maxLength(300)]],
    Archived_Product_Description_Arabic: ['', [Validators.maxLength(300)]],
    cat_1: [0, [Validators.required, Validators.min(1)]],
    cat_2: [0, [Validators.required, Validators.min(1)]],
    cat_3: [0, [Validators.required, Validators.min(1)]],
    Archived_Product_Related_Catigory_4: [0, [Validators.required, Validators.min(1)]],
    Archived_Product_Related_Branch: [0, [Validators.required, Validators.min(1)]],
    Archived_Products_Price: [0, [Validators.required, Validators.min(1)]],
    Archived_Product_Note: ['', [Validators.maxLength(300)]],
    Archived_Products_Delivery_Status:[0, [Validators.required, Validators.min(1)]],
    Archived_Product_Company_Delivery_Point: [0, [Validators.required, Validators.min(0)]],
    Archived_Product_User_Delivery_Point: [0, [Validators.required, Validators.min(0)]],
  });
  reset_formGroup_insert_Archived_Products() {
    this.formGroup_insert_Archived_Products.patchValue({
      Archived_Product_Name: '',
      Archived_Product_Name_Arabic: '',
      Archived_Product_Description: '',
      Archived_Product_Description_Arabic: '',
      Archived_Product_Related_Catigory_4: 0,
      Archived_Product_Note: '',
      Archived_Product_Company_Delivery_Point: 0,
      Archived_Product_User_Delivery_Point: 0,
      Archived_Products_Delivery_Status:0,
    });
  }
  cat_1_change(cat_1){
    this.cat_1_selected = cat_1.value;    
    this.refresh_cat_2();
    this.cat_2_selected = 0;this.cat_3_selected = 0;this.cat_4_selected = 0;
   }
  refresh_cat_2(){
    if(this.cat_1_selected==0){
      this.Cat_2_list = [];this.Cat_3_list = [];this.Cat_4_list = [];
    }
    else{this.service.get_catigory_2_list_min(this.cat_1_selected).subscribe(res=>{
      this.Cat_2_list = res;this.Cat_3_list = [];this.Cat_4_list = [];
    }
      ,err=>{this.notificationService.send_fail_message("Failed to load")})}
  }
  cat_2_change(cat_2){this.cat_2_selected = cat_2.value;
    this.refresh_cat_3();
    this.cat_3_selected = 0;this.cat_4_selected = 0;
  }
  refresh_cat_3(){
    if(this.cat_2_selected==0){this.Cat_3_list = [];this.Cat_4_list = [];}
    else{this.service.get_catigory_3_list_min(this.cat_2_selected).subscribe(res=>{
      this.Cat_3_list = res;
    }
      ,err=>{this.notificationService.send_fail_message("Failed to load")})}
  }
  cat_3_change(cat_3){this.cat_3_selected = cat_3.value;
     this.refresh_cat_4();
     this.cat_4_selected = 0;
  }
  refresh_cat_4(){
    if(this.cat_3_selected==0){this.Cat_4_list = [];}
    else{this.service.get_catigory_4_list_min(this.cat_3_selected).subscribe(res=>{
      this.Cat_4_list = res;
    }
      ,err=>{this.notificationService.send_fail_message("Failed to load")})}
  }
  ngOnInit(): void {
    this.service.product_delivery_Statuses().subscribe(res=>{this.prod_delivery_statuses = res;});
    this.service.get_catigory_1_list_min().subscribe(res => this.Cat_1_list = res );
    this.reset_formGroup_insert_Archived_Products();
    this.formGroup_insert_Archived_Products.controls['Archived_Product_Related_Branch'].setValue(+this.currentRoute.snapshot.paramMap.get('branch_id'));
  }
  create_product(fg: FormGroup) {
//     console.log(fg.value);
//     const invalid = [];
//     const controls = fg.controls;
//     for (const name in controls) {
//         if (controls[name].invalid) {
//             invalid.push(name);
//         }
//     }
// console.log(invalid)

    if (fg.valid) {    
      this.service.insert_Archived_Products(fg.value).subscribe(res => {
        this.router.navigate(['/main/update_product/'+ res['archived_Product_Id'] + '/' + res['archived_Product_Id_Date']]);
      }, err => { 
        // console.log(err);
        this.notificationService.send_fail_message("Could not create the Product"); });
    }
  }
}
